<template>
  <MasterLayout>

    <div class="flex flex-col justify-between max-h-full h-full  " style="">

      <div class="flex-1 overflow-y-hidden h-full max-h-full flex flex-col relative">

        <div class="shrink-0">
          <div class="mt-[12%] italic font-bold  text-white text-5xl">
            {{ $t('to_you') }}
          </div>

          <div class="text-white  italic opacity-90 text-2xl  font-roboto font-hairline">
            {{ $t('something') }}  <span class="italic">{{ $t('extra') }} </span>
          </div>

          <div class="w-full py-2 pr-[24px]">
            <div class="px-4">
              <Stepper :steps="steps" v-model="step" class="mt-8"/>
            </div>
          </div>

        </div>

        <div class="flex flex-col grow-shrink overflow-y-scroll">
          <MealExtraStep :data="contentStore.selectedCategory.mealExtra1" :cartItemIndex="cartItemIndex" :order="1"
                         :step="step"/>
          <MealExtraStep :data="contentStore.selectedCategory.mealExtra2" :cartItemIndex="cartItemIndex" :order="2"
                         :step="step"/>
          <MealExtraStep :data="contentStore.selectedCategory.mealExtra3" :cartItemIndex="cartItemIndex" :order="3"
                         :step="step"/>
        </div>

      </div>

      <div class="shrink-0">
        <hr class="border-white opacity-10 mb-4">
        <div class="flex pb-4 items-center justify-between">

          <!--        <div v-if="step === 0" class="">-->
          <!--          <div class="text-white text-xs opacity-85">-->
          <!--            Cena:-->
          <!--          </div>-->
          <!--          <div class="text-secondary-0 font-bold text-3xl">-->
          <!--            16 €-->
          <!--          </div>-->
          <!--        </div>-->

          <div v-if="step > 1 " class="">
            <div @click="step--"
                 class=" w-[54px] h-[54px] z-20  top-[1px] right-[1px] bg-primary-0  rounded-full  flex items-center justify-center ">
              <font-awesome-icon :icon="['fal','angle-left']" class="text-white text-3xl"/>
            </div>
          </div>
          <div v-else></div>

          <button @click="step++"
                  v-if="step < 3"
                  class="bg-secondary-0 px-8 rounded-r-full py-4 text-primary-0 font-bold text-lg relative rounded-bl-full ">

            <span class="mr-12">
        {{ $t('next') }}
      </span>

            <div
                class=" w-[54px] h-[54px] z-20 absolute top-[1px] right-[1px] bg-primary-0 border-secondary-0 rounded-full  flex items-center justify-center ">
              <font-awesome-icon :icon="['fal','angle-right']" class="text-white text-lg"/>
            </div>

          </button>

          <button v-else @click="goToCart"
                  class="bg-secondary-0 px-8 rounded-r-full py-4 text-primary-0 font-bold text-lg relative rounded-bl-full ">
                <span class="mr-12">
                 {{ $t('add_to_cart') }}
              </span>

            <div
                class=" w-[54px] h-[54px] z-20 absolute top-[1px] right-[1px] bg-primary-0 border-secondary-0 rounded-full  flex items-center justify-center ">
              <font-awesome-icon :icon="['fal','basket-shopping']" class="text-white text-lg"/>
            </div>

          </button>


        </div>
      </div>

    </div>


  </MasterLayout>
</template>

<script setup>
import {ref, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import MasterLayout from "@/layouts/MasterLayout.vue";
import MealExtraStep from "@/components/meal-side-dish/MealExtraStep.vue";

const step = ref(1)
import Stepper from "@/components/stepper/Index.vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {ROUTE_NAMES} from "@/router"
import useContentStore from "@/stores/contentStore"

const router = useRouter()
const route = useRoute()

const contentStore = useContentStore()

const steps = computed(() => [
  contentStore.selectedCategory?.mealExtra1?.name ?? '-',
  contentStore.selectedCategory?.mealExtra2?.name ?? '-',
  contentStore.selectedCategory?.mealExtra3?.name ?? '-',
])

const cartItemIndex = Number(route.query.cartItemIndex)

//
const goHome = () => {
  router.push({
    name: ROUTE_NAMES.HOME_VIEW
  })
}

const goToCart = () => {
  router.push({
    name: ROUTE_NAMES.CART_VIEW
  })
}

</script>

<style>

.bounce-enter-active {
  animation-delay: .5s;
  animation: bounce-in .5s;
}

.bounce-leave-active {
  //animation: bounce-out .5s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}
</style>