<template>
  <div class="flex items-center" :class="{'flex-col-reverse':isVertical}">
    <div @click="minus" class="w-[32px] h-[32px] bg-secondary-0 rounded flex items-center justify-center">
      <font-awesome-icon :icon="['fas', 'minus']" class="text-primary-0"/>
    </div>
    <span class="shrink-0 mx-3 text-white  text-lg" :class="{'my-1':isVertical}">
      {{ value }}
    </span>
    <div @click="value++" class="w-[32px] h-[32px] bg-secondary-0 rounded flex items-center justify-center">
      <font-awesome-icon :icon="['fas', 'plus']" class="text-primary-0"/>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  isVertical: {
    type: Boolean,
    default: false
  },
})
const value = defineModel()

const minus = () => {
  if (value.value >= 1) {
    value.value--
  }
}

</script>