<template>
  <div :class="{ 'opacity-0': !categoryDataLoaded }"
       class="flex flex-col 0"
       @click="goToSingleMeal(index)"
  >
    <div class="h-[90px] w-full flex items-end shrink-0">
      <img :src="`${IMG_URL}/${image}`" class=" object-contain w-full max-h-full  mx-auto"/>
    </div>
    <div class="w-full h-full flex flex-col justify-between px-0">
      <div class="">
        <div ref="titleHolder" v-html="itemTitle" class="font-roboto  mt-2 text-white text-xl font-medium">
        </div>
        <div class="text-white text-sm opacity-75">
          <!--          {{ props.item.text }}-->
          {{ translateVal('text', props.item) }}
        </div>
      </div>
      <div class="flex justify-between mt-1 items-end">
        <div class="text-secondary-0 font-bold text-xl">
          {{ price }} €
        </div>
        <div class="opacity-75 mb-1 text-white font-bold text-sm">
          {{ props.item.weight }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, nextTick, useTemplateRef} from "vue";
import {IMG_URL} from "@/content/lists";
import {ROUTE_NAMES} from "@/router";
import {useRouter} from "vue-router";
import {translateVal} from "@/plugins/l18m";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  categoryDataLoaded: {
    type: Boolean,
    required: true
  }
})
const router = useRouter()

const goToSingleMeal = index => router.push({
  name: ROUTE_NAMES.SINGLE_MEAL_VIEW,
  query: {index}
})

const image = computed(() => props.item.images[1] ?? props.item.images[0])
const price = computed(() => Number(props.item?.pluItem?.pluPrice).toFixed(2))

const titleHolder = useTemplateRef('titleHolder')

const itemTitle = ref(translateVal('title', props.item))
// const itemTitle = ref(props.item.title)
const isMultilineText = ref(false)

onMounted(() => {
  nextTick(() => {
    const element = titleHolder.value;

    // Získame výšku jedného riadku
    const lineHeight = parseFloat(getComputedStyle(element).lineHeight);

    // Získame celkovú výšku prvku
    const totalHeight = element.clientHeight;

    // Vypočítame počet riadkov
    const numberOfLines = Math.round(totalHeight / lineHeight);

    // Skontrolujeme, či je text na viac ako jednom riadku
    const isWrapped = numberOfLines > 1;
    isMultilineText.value = isWrapped

    if (!titleHolder.value || isMultilineText.value) return

    const splitTitle = itemTitle.value?.split(' ') ?? []

    if (splitTitle.length === 1 || splitTitle.length === 0) return

    // move last word to the next line
    const lastWord = splitTitle.pop()
    const firstLine = splitTitle.join(' ')

    itemTitle.value = `${firstLine} <br/> ${lastWord} `

  });
});

</script>