<template>

  <master-layout :has-navigation-menu="false" sun="tr-to-bl">

    <!--  back button -->
    <template #prepend>
      <div class="w-full">
        <div v-if="step === 0"
             @click="$router.go(-1)"
             class=" w-[52px] h-[52px] z-20   top-[22px] left-[22px] bg-primary-0  flex items-center justify-center ">
          <font-awesome-icon :icon="['fas','angle-left']" class="text-white text-4xl"/>
        </div>
      </div>
    </template>

    <div class="flex flex-col justify-between max-h-full h-full  " style="">

      <div class="flex-1 overflow-y-hidden h-full max-h-full flex flex-col relative">

        <div class="">

          <img :src="`${IMG_URL}/${selectedMeal?.images[1] ?? selectedMeal?.images[0]}`" class="w-full mt-12 h-auto"/>
          <!--          <img src="/images/b2.png" class="w-full mt-12 h-auto"/>-->

          <div class="text-white mt-8 flex items-center font-bold text-4xl">
            <span class="mr-4 italic"> {{ selectedMealTitle.title }}</span>
            <span v-if="selectedMealTitle.title.length <16" :data-title="selectedMealTitle.endWord"
                  class="burger-highlight h-[45px] relative"></span>
          </div>

          <div v-if="selectedMealTitle.title.length >=16"
               class="text-white mt-2 pl-2 mb-3 flex items-center font-bold text-4xl">
            <span :data-title="selectedMealTitle.endWord" class="burger-highlight h-[45px] relative"></span>
          </div>

          <hr class="opacity-10 mt-4 border-white">

        </div>

        <transition name="scale" mode="out-in">
          <div v-if="step === 0" key="step-0">

            <div class="text-white mt-4  font-light">
              <span class=" font-medium"> {{ $t('meal_content') }} </span>
              <span class="opacity-75">{{ translateVal('text', selectedMeal) }}</span>
              <!--              <span class="opacity-75">{{ selectedMeal.text }}</span>-->
            </div>

            <div class="text-white  mt-1 font-light">
              <span class=" font-medium">{{ $t('weight') }}  </span>
              <span class="opacity-75">{{ selectedMeal.weight }}</span>
            </div>

            <div class="text-white  mt-1 font-light">
              <span class=" font-medium">{{ $t('allergens') }} </span>
              <span class="opacity-75">{{ selectedMeal.allergens }}</span>
            </div>

          </div>

          <div v-else class="w-full mt-7 shrink grow flex flex-col  overflow-y-scroll">
            <div v-for="(part,i) in parts" class="flex mb-3  items-center  justify-between">
              <div class="text-white capitalize  ">
                {{ part.text }}
              </div>
              <MyCheckBox v-model="parts[i].checked"/>
            </div>
          </div>


        </Transition>

      </div>

      <div class="shrink-0">
        <hr class="border-white opacity-10 mb-4">
        <div class="flex pb-4 items-center justify-between">

          <div v-if="step === 0" class="">
            <div class="text-white text-xs opacity-85">
              {{ $t('price') }}
            </div>
            <div class="text-secondary-0 font-bold text-3xl">
              {{ price }} €
            </div>
          </div>

          <BackButton v-else @click="step = 0"/>

          <FloatButton v-if="step === 0" @click="step = 1" :icon="['fal','angle-right']">
            <span class="mr-12">
               {{ $t('next') }}
            </span>
          </FloatButton>

          <FloatButton v-else @click="openMealSideDish" :icon="['fal','basket-shopping']">
            <span class="mr-12">
               {{ $t('add_to_cart') }}
            </span>
          </FloatButton>

        </div>
      </div>

    </div>

  </master-layout>

</template>

<script setup>
import {ref, computed, watch} from 'vue'
import MyCheckBox from "@/components/MyCheckBox.vue";
import {useRoute, useRouter} from "vue-router";
import {ROUTE_NAMES} from "@/router";
import MasterLayout from "@/layouts/MasterLayout.vue";
import useLayoutStore from "@/stores/layoutStoreStore";
import useContentStore from "@/stores/contentStore";
import {IMG_URL} from "@/content/lists";
import useCartStore from "@/stores/cartStore";
import FloatButton from "@/components/buttons/FloatButton.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import {translateVal} from "@/plugins/l18m";

const step = ref(0)

const layoutStore = useLayoutStore()
const contentStore = useContentStore()

const stepTransition = computed(() => {
  return step.value === 0 ? 'slide-right' : 'slide-left';
})

const router = useRouter()
const route = useRoute()

const selectedMealIndex = Number(route.query.index)

const selectedMeal = computed(() => {
  return contentStore.itemsInCategory[selectedMealIndex]
})

const price = computed(() => Number(selectedMeal.value?.pluItem?.pluPrice ?? 0).toFixed(2))

const selectedMealTitle = computed(() => {
  const t = translateVal('title', selectedMeal.value)
  const text = t.replace(/\s+$/, '') ?? '';
  // const text = selectedMeal.value.title.replace(/\s+$/, '') ?? '';
  const split = text.split(' ')
  const title = split.slice(0, split.length - 1).join(' ')
  const endWord = split[split.length - 1] ?? '-'
  return {
    title,
    endWord
  }
})

const parts = ref([])

watch(selectedMeal, () => {
  // parts.value = (selectedMeal.value.text ?? '').split(',').map(item => ({
  parts.value = (translateVal('text', selectedMeal.value) ?? '').split(',').map(item => ({
    text: item,
    checked: true
  }))
}, {immediate: true, deep: true})


const cartStore = useCartStore()

// precache images
watch(() => step.value, (value) => {
  if (value === 1) {
    const mealExtra = contentStore?.selectedCategory?.mealExtra1?.products ?? []
    console.log('mealExtra', mealExtra)
    mealExtra.forEach(item => {
      if (item?.product?.images[0]) {
        const img = new Image()
        img.src = `${IMG_URL}/${item.product.images[0]}`
      }
    })
  }
})


const openMealSideDish = () => {

  const removed = parts.value.filter(item => item.checked === false).map(item => item.text).join(', ')

  const {index: addedOnIndexCartStore} = cartStore.addProductToCart({
    product: {...selectedMeal.value},
    removeFromMeal: removed,
    extra: {}
  })

  router.push({
    name: ROUTE_NAMES.MEAL_SIDE_DISH,
    query: {
      cartItemIndex: addedOnIndexCartStore,
    }
  })

}

</script>

<style scoped lang="scss">

.burger-highlight::before {
  content: attr(data-title);
  position: absolute;
  top: 0;
  left: 0;
  //height: 100%;
  background-color: #fbb665;
  transform: skew(-10deg);
  color: #232227;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  //padding-left: 4px;
  padding: 1px 8px;
  //padding-top: 4px;
  //padding-bottom: 4px;
  z-index: 10;
}

/* Prechod z prava do ľava */
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 0.2s ease;
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-right-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-to, .slide-right-leave-from {
  transform: translateX(0%);
}

/* Prechod z ľava do prava */
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 0.2s ease;
}

.slide-left-enter-from {
  transform: translateX(-100%);
}

.slide-left-leave-to {
  transform: translateX(100%);
}

.slide-left-enter-to, .slide-left-leave-from {
  transform: translateX(0%);
}

.aa::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px; /* Výška prechodu */
  background: linear-gradient(transparent, black);
  opacity: 0.1;
}
</style>