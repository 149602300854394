import {defineStore} from 'pinia'
import {ref, reactive, computed} from "vue"
import {useRouter} from 'vue-router'
import {ROUTE_NAMES} from "@/router"
import useContentStore from "@/stores/contentStore"

const useLayoutStore = defineStore('layoutStore', () => {

    const router = useRouter()
    const contentStore = useContentStore()

    const _sideMenuOpened = ref(false)
    const sideMenuOpened = computed(() => _sideMenuOpened.value)

    const openSideMenu = () => {
        _sideMenuOpened.value = true
    }

    const closeSideMenu = () => {
        _sideMenuOpened.value = false
    }



    return {
        sideMenuOpened,
        openSideMenu,
        closeSideMenu
    }

})

export default useLayoutStore