<template>
  <span class="burger-highlight bg-transparent h-[26px] relative" :data-title="props.title"
        style="width: calc(33% - 12px)"
  ></span>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true
  }
})
</script>

<style scoped lang="scss">

.burger-highlight::before {
  background-color: #fbb665;
  color: #232227;
  content: attr(data-title);
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-14deg);
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  width: 121px;
  text-align: center;
  font-size: 18px;
  z-index: 10;
}

.step-active::before {
  @apply bg-primary-0 border border-secondary-0 text-secondary-0;
}

.step-done::before {
  @apply bg-secondary-0 border-secondary-0 border text-primary-0;
}

.step-inactive::before {
  @apply bg-transparent text-white;
}

</style>