import {defineStore} from 'pinia'
import {ref, reactive, computed} from "vue"
import Supabase from "@/plugins/Supabase";
import usePluStore from "@/stores/pluStore";

const DELIVERY_TYPES = {
    PERSONAL_PICK_UP: 'personal_pick_up',
    ADDRESS: 'address'
}

const PICK_UP_PLACES = [
    {
        id: 'BO',
        title: 'Hajcer Bošany, Mlynská 979/10',
    },
    {
        id: 'PE',
        title: 'Hajcer Partizánske, Škultétyho 1078',
    }
]

const useDeliveryStore = defineStore('deliveryStore', () => {

    const pluStore = usePluStore()

    const loading = ref(false)
    const deliveryPlaces = ref([])

    const loadDeliveryPlaces = async () => {
        loading.value = true
        // const {data, error} = await Supabase.from('v2_Hajcer_App_DeliveryPlaces').select('*')
        const {data, error} = await Supabase
            .from('v2_Hajcer_App_DeliveryPlaces')
            .select(`
    *,
    pluItem:v2_Hajcer_PluItems (*)
  `);

        if (error) {
            alert('Error loading delivery places')
            return
        }
        deliveryPlaces.value = data
        loading.value = false
    }

    // delivery type
    const selectedDeliveryType = ref(DELIVERY_TYPES.PERSONAL_PICK_UP)
    // const selectedDeliveryType = ref(null)
    const hasSelectedDeliveryType = computed(() => selectedDeliveryType.value !== null)
    const setDeliveryType = type => selectedDeliveryType.value = type
    const isPersonalPickUp = computed(() => selectedDeliveryType.value === DELIVERY_TYPES.PERSONAL_PICK_UP)

    // personal pick up
    // const pickUpPlace = ref('BO')
    const pickUpPlace = ref(null)
    const setPickUpPlace = place => {
        pickUpPlace.value = place
    }
    const hasSelectedPickUpPlace = computed(() => pickUpPlace.value !== null)

    const addressDelivery = reactive({
        city: null,
        street: '',
    })

    const addressDeliveryCityIsSet = computed(() => addressDelivery.city !== null)
    const addressDeliveryStreetIsSet = computed(() => addressDelivery.street !== '')
    const addressDeliveryIsSet = computed(() => addressDeliveryCityIsSet.value && addressDeliveryStreetIsSet.value)

    // address delivery
    const deliveryIsSet = computed(() => {
        if (isPersonalPickUp.value) return hasSelectedPickUpPlace.value
        return addressDeliveryIsSet.value
    })

    const deliveryPlaceSymbol = computed(() => {
        if (isPersonalPickUp.value) {
            return pickUpPlace.value
        } else if (addressDeliveryIsSet) {
            return addressDelivery.city.restaurantPlace
        }
        return null
    })

    const deliveryPrice = computed(() => {
        if (pluStore.appSettings.freeDeliveryActive) return 0
        if (isPersonalPickUp.value) return 0
        if (!addressDeliveryCityIsSet.value) return 0
        return addressDelivery.city?.pluItem?.pluPrice ?? 0
    })

    const isDeliveryFree = computed(() => deliveryPrice.value === 0)

    const deliveryAddress = computed(() => {
        if (isPersonalPickUp.value) return 'Osobný odber'
        return `${addressDelivery.city?.name ?? ''}, ${addressDelivery.street}`
    })

    const invalidDeliveryCity = computed(() => {
        if (isPersonalPickUp.value) {
            return false
        }
        if (addressDelivery.street.toString().length === 0) {
            return false
        }
        return !!addressDelivery.city === false
    })

    return {
        invalidDeliveryCity,
        deliveryAddress,
        isDeliveryFree,
        selectedDeliveryType,
        hasSelectedDeliveryType,
        deliveryPlaceSymbol,
        isPersonalPickUp,
        setDeliveryType,
        pickUpPlace,
        setPickUpPlace,
        hasSelectedPickUpPlace,
        deliveryIsSet,
        deliveryPlaces,
        loadDeliveryPlaces,
        addressDelivery,
        addressDeliveryIsSet,
        addressDeliveryCityIsSet,
        addressDeliveryStreetIsSet,
        deliveryPrice,
        loading
    }

})

export {
    DELIVERY_TYPES,
    PICK_UP_PLACES
}

export default useDeliveryStore