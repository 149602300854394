<template>

  <MasterLayout>

    <div class="flex-grow flex max-h-full flex-col  ">

      <!--   categories filter -->
      <div class="filter-container" :class="{ 'content-hidden': !isAtTop }">
        <div class="filter">
          <div class="mt-[20%] italic font-bold  text-white text-5xl">
            {{ contentStore.selectedCategory?.title }}
          </div>

          <div class="text-white italic  opacity-90 text-2xl  font-roboto font-hairline">
            {{ contentStore.selectedCategory?.description }}
          </div>

          <div class="w-full mt-8 flex items-center">

            <div ref="menuContainer" class="flex  overflow-x-auto grow shrink ">

              <div v-for="(category,i) in contentStore.categories"
                   @click="contentStore.setSelectedCategoryId(category.id)"
                   :ref="el => { if (el) menuItemRefs[i] = el }"
                   class="flex py-3 px-6 items-center">

                      <span class="w-[6px] h-[6px] rounded-full mr-2"
                            :class="category.id !== contentStore.selectedCategoryId ? 'bg-primary-0':'bg-secondary-0'">
                      </span>
                <span class="text-white"
                      :class="{'font-bold': category.id !== contentStore.selectedCategoryId,
                            'opacity-75': category.id !== contentStore.selectedCategoryId}">
                  {{ translateVal('name', category)}}
                </span>
              </div>

            </div>

            <div v-if="contentStore.prevCategoryId"
                 @click="contentStore.setSelectedCategoryId(contentStore.prevCategoryId)"
                 class="ml-8 w-[48px] h-[48px] justify-center flex items-center shrink-0 border rounded-lg border-grey-0 cursor-pointer">
              <font-awesome-icon :icon="['fal','angle-left']" class="text-white text-xl"/>
            </div>
            <div v-if="contentStore.nextCategoryId"
                 @click="contentStore.setSelectedCategoryId(contentStore.nextCategoryId)"
                 :class="{'ml-4':!contentStore.isSelectedFirstCategory}"
                 class=" w-[48px] h-[48px] justify-center flex items-center shrink-0 border rounded-lg border-grey-0 cursor-pointer">
              <font-awesome-icon :icon="['fal','angle-right']" class="text-white text-xl"/>
            </div>

          </div>

        </div>
      </div>

      <transition-group name="list"
                        tag="div"
                        ref="scrollContainer"
                        @scroll="handleScroll"
                        class="grid shrink  overflow-y-scroll pb-8 grid-cols-2 pt-8 gap-8"

      >

        <HomeSingleMeal v-for="(item, index) in contentStore.itemsInCategory"
                        :key="index"
                        :index="index"
                        :categoryDataLoaded="categoryDataLoaded"
                        :item="item"
        />

        <div v-if="contentStore.itemsInCategory.length === 4"
             :class="{ 'opacity-0': !categoryDataLoaded }"
             class="flex h-[120px] flex-col "
        >
        </div>

      </transition-group>


    </div>
  </MasterLayout>
</template>

<script setup>
import MasterLayout from "@/layouts/MasterLayout.vue";
import {ref, onMounted, nextTick, onUnmounted, watch, useTemplateRef} from 'vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useRouter} from "vue-router";
import {ROUTE_NAMES} from "@/router";
import HomeSingleMeal from "@/components/layout/HomeSingleMeal.vue";

import useLayoutStore from "@/stores/layoutStoreStore";
import useContentStore from "@/stores/contentStore";
import {IMG_URL} from "@/content/lists";
import {translateVal} from "@/plugins/l18m";

const router = useRouter()
const layoutStore = useLayoutStore()
const contentStore = useContentStore()

/*
 * Content scroll detect if top to show/hide filter
 */
const scrollContainer = useTemplateRef('scrollContainer')
const isAtTop = ref(true);

const handleScroll = () => {
  isAtTop.value = scrollContainer.value.$el.scrollTop < 0.1; // You can adjust this threshold
}

onMounted(() => {
  console.log('scrollContainer', scrollContainer.value)
  console.log('scrollContainer')
  if (!scrollContainer.value?.$el) return
  scrollContainer.value.$el.addEventListener('scroll', handleScroll);
})

onUnmounted(() => {
  if (!scrollContainer.value?.$el) return
  scrollContainer.value.$el.removeEventListener('scroll', handleScroll);
})

const menuContainer = ref(null)
const menuItemRefs = ref([])

/*
 * When selected category changes, scroll to selected item
 */
onMounted(() => {
  watch(() => contentStore.selectedCategoryId, async (newVal, oldVal) => {
    await nextTick()
    const selectedItem = menuItemRefs.value[contentStore.selectedCategoryIndex]
    if (!selectedItem || !menuContainer.value) return
    const containerRect = menuContainer.value.getBoundingClientRect()
    const itemRect = selectedItem.getBoundingClientRect()
    const desiredScrollLeft = itemRect.left + menuContainer.value.scrollLeft - containerRect.left - (containerRect.width / 2) + (itemRect.width / 2)
    menuContainer.value.scrollTo({
      left: desiredScrollLeft,
      behavior: 'smooth'
    })
  }, {immediate: true})
})

// categoryDataLoaded ensure animation works even when page is left and returned with same filter
const categoryDataLoaded = ref(false)
watch(() => contentStore.selectedCategoryId, async (newVal, oldVal) => {
  await contentStore.loadCategoryData()
  categoryDataLoaded.value = true
}, {immediate: true})

const goToPickDelivery = () => {
  router.push({
    name: ROUTE_NAMES.PICK_DELIVERY_VIEW
  })
}

const goToSingleMeal = index => {
  router.push({
    name: ROUTE_NAMES.SINGLE_MEAL_VIEW,
    query: {index}
  })
}

</script>

<style scoped lang="scss">
/*
 * Filter container
 */
.filter-container {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 1;
  //transform: translateY(0);
  //overflow: hidden;
  //height: 100%;
}

.content-hidden {
  opacity: 0;
  transform: translateY(-100%);
  max-height: 0;
}

.filter {
  transition: transform 0.3s ease;
}

.content-hidden .filter {
  transform: translateY(-100%);
}

/*
 * Animácie pre transition-group
 */
.list-enter-active, .list-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.list-enter-from, .list-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.list-leave-active {
  opacity: 0 !important;
}


</style>