export const IMG_URL = 'https://agzgtvopyggthwhdvjww.supabase.co/storage/v1/object/public/app-products-images'

export const appTexts = {
    "cz": {
        "or": "nebo",
        "sum": "Celkem",
        "card": "Platební karta",
        "cash": "Hotovost",
        "city": "Město / Obec",
        "date": "Datum",
        "done": "Hotovo",
        "edit": "Upravit",
        "free": "Zdarma",
        "next": "Dále",
        "note": "Poznámka k objednávce",
        "save": "Uložit",
        "test": "test cz",
        "extra": "extra",
        "login": "Přihlášení",
        "price": "Cena:",
        "logout": "Odhlásit",
        "resend": "Odeslat znovu",
        "street": "Ulice a orientační číslo",
        "terms1": "obchodními podmínkami",
        "terms2": "zpracování osobních údajů",
        "to_you": "Dopřejte si",
        "verify": "Ověřit",
        "weight": "Hmotnost:",
        "account": "Účet",
        "in_cart": "v košíku",
        "removed": "Odstraněno",
        "waiting": "Čeká na přijetí",
        "canceled": "Zrušeno",
        "delivery": "Doprava",
        "link_acc": "Účet",
        "personal": "Osobně",
        "sms_code": "SMS kód",
        "acc_phone": "Telefonní číslo:",
        "allergens": "Alergeny:",
        "cart_send": "Odeslat",
        "link_blog": "Blog",
        "meal_pack": "Obal na jídlo",
        "my_orders": "Mé objednávky",
        "something": "něco",
        "week_head": "PO - NE",
        "agree_with": "Souhlasím s",
        "cart_title": "Košík",
        "cart_total": "Celková částka",
        "link_login": "Přihlášení",
        "to_address": "Doručení na adresu",
        "add_to_cart": "Přidat do košíku",
        "link_orders": "Mé objednávky",
        "loading_app": "Načítám...",
        "pay_waiting": "Čeká se na dokončení platby",
        "sure_logout": "Opravdu se chcete odhlásit?",
        "link_contact": "Kontakt",
        "login_action": "Přihlásit",
        "meal_content": "Složení:",
        "order_status": "Stav objednávky",
        "phone_number": "Telefonní číslo",
        "cart_delivery": "Doručení",
        "delivery_type": "Vyberte si způsob doručení",
        "acc_error_save": "Nastala chyba při ukládání dat",
        "bottle_deposit": "Záloha za nápoj",
        "delivery_hours": "Doručovací hodiny",
        "delivery_price": "Cena dopravy",
        "no_chosen_food": "Zatím nemáte žádné jídlo",
        "pay_dont_close": "Prosím čekejte a nezavírejte aplikaci",
        "sms_send_error": "Nepodařilo se odeslat SMS",
        "current_offline": "Momentálně jsme offline, zkuste později nebo nám zavolejte.",
        "go_to_pick_meal": "Přejít na výběr jídel",
        "meal_extra_pack": "Obal na přílohu",
        "payment_options": "Možnosti platby",
        "personal_pickup": "Osobní odběr",
        "verify_sms_code": "Ověřte SMS kód",
        "address_delivery": "Adresa doručení",
        "order_send_error": "Nepodařilo se odeslat objednávku",
        "sms_verify_error": "Nepodařilo se ověřit SMS",
        "window_autoclose": "Toto okno se automaticky zavře do",
        "acc_name_username": "Jméno a příjmení",
        "estimate_delivery": "Odhadovaný čas doručení je",
        "name_and_username": "Jméno a příjmení",
        "contact_restaurant": "Kontaktujte restauraci",
        "no_delivery_option": "Do tohoto města / obce nenabízíme doručení",
        "order_send_success": "Vaše objednávka s číslem {id} byla úspěšně odeslána",
        "payment_and_summary": "Platba a shrnutí objednávky",
        "select_pic_up_place": "Vybrat odběrné místo",
        "error_load_my_orders": "Chyba při načítání objednávek",
        "waiting_for_delivery": "Přijata, čeká na doručení",
        "discover_without_login": "Prohlížet menu bez přihlášení",
        "use_other_phone_number": "Použít jiné telefonní číslo",
        "dont_close_until_payment_done": "Nezavírejte okno dokud platba nebude hotová"
    }, "de": {
        "or": "oder",
        "sum": "Zusammen",
        "card": "Zahlungskarte",
        "cash": "Kasse",
        "city": "Stadt/Gemeinde",
        "date": "Datum",
        "done": "Erledigt",
        "edit": "Bearbeiten",
        "free": "Frei",
        "next": "Nächste",
        "note": "Bestellhinweis",
        "save": "Speichern",
        "test": "test de",
        "extra": "extra",
        "login": "Login",
        "price": "Preis:",
        "logout": "Abmelden",
        "resend": "Nochmals abschicken",
        "street": "Straße und Referenznummer",
        "terms1": "Geschäftsbedingungen",
        "terms2": "Verarbeitung personenbezogener Daten",
        "to_you": "Gönnen Sie sich etwas",
        "verify": "Verifizieren",
        "weight": "Gewicht:",
        "account": "Konto",
        "in_cart": "im Korb",
        "removed": "Entfernt",
        "waiting": "Warten auf Akzeptanz",
        "canceled": "Abgesagt",
        "delivery": "Lieferung",
        "link_acc": "Konto",
        "personal": "Persönlich",
        "sms_code": "SMS-Code",
        "acc_phone": "Telefonnummer:",
        "allergens": "Allergene:",
        "cart_send": "Schicken",
        "link_blog": "Blog",
        "meal_pack": "Lebensmittelverpackung",
        "my_orders": "Meine Bestellungen",
        "something": "etwas",
        "week_head": "MO – SO",
        "agree_with": "Ich stimme zu",
        "cart_title": "Korb",
        "cart_total": "Gesamtbetrag",
        "link_login": "Login",
        "to_address": "Lieferung an die Adresse",
        "add_to_cart": "In den Warenkorb legen",
        "link_orders": "Meine Bestellungen",
        "loading_app": "Laden...",
        "pay_waiting": "Bis zum Abschluss der Zahlung",
        "sure_logout": "Möchten Sie sich wirklich abmelden?",
        "link_contact": "Kontakt",
        "login_action": "Einloggen",
        "meal_content": "Zutaten:",
        "order_status": "Bestellstatus",
        "phone_number": "Telefonnummer",
        "cart_delivery": "Lieferung",
        "delivery_type": "Wählen Sie eine Versandart",
        "acc_error_save": "Beim Speichern der Daten ist ein Fehler aufgetreten",
        "bottle_deposit": "Getränkepfand",
        "delivery_hours": "Lieferzeiten",
        "delivery_price": "Versandpreis",
        "no_chosen_food": "Sie haben noch kein ausgewähltes Lebensmittel",
        "pay_dont_close": "Bitte warten Sie und schließen Sie die Anwendung nicht",
        "sms_send_error": "SMS konnte nicht gesendet werden",
        "current_offline": "Wir sind derzeit offline. Versuchen Sie es später noch einmal oder rufen Sie uns an.",
        "go_to_pick_meal": "Gehen Sie zur Auswahl der Mahlzeiten",
        "meal_extra_pack": "Aufsatzabdeckung",
        "payment_options": "Zahlungsmöglichkeiten",
        "personal_pickup": "Persönliche Sammlung",
        "verify_sms_code": "Überprüfen Sie den SMS-Code",
        "address_delivery": "Lieferadresse",
        "order_send_error": "Die Bestellung konnte nicht gesendet werden.",
        "sms_verify_error": "SMS konnte nicht überprüft werden",
        "window_autoclose": "Dieses Fenster wird automatisch geschlossen, bis",
        "acc_name_username": "Vor- und Nachname",
        "estimate_delivery": "Die voraussichtliche Lieferzeit beträgt",
        "name_and_username": "Vor- und Nachname",
        "contact_restaurant": "Kontaktieren Sie das Restaurant",
        "no_delivery_option": "Wir bieten keine Lieferung in diese Stadt/Ort an",
        "order_send_success": "Ihre Bestellung mit der Nummer {id} wurde erfolgreich versendet",
        "payment_and_summary": "Zahlungs- und Bestellübersicht",
        "select_pic_up_place": "Wählen Sie eine Sammelstelle",
        "error_load_my_orders": "Fehler beim Laden der Bestellungen",
        "waiting_for_delivery": "Erhalten, warte auf Lieferung",
        "discover_without_login": "Durchsuchen Sie das Menü, ohne sich anzumelden",
        "use_other_phone_number": "Verwenden Sie eine andere Telefonnummer",
        "dont_close_until_payment_done": "Schließen Sie das Fenster erst, wenn die Zahlung abgeschlossen ist"
    }, "en": {
        "or": "or",
        "sum": "Total",
        "card": "Payment card",
        "cash": "Cash",
        "city": "City / Municipality",
        "date": "Date",
        "done": "Done",
        "edit": "Edit",
        "free": "Free",
        "next": "Next",
        "note": "Order note",
        "save": "Save",
        "test": "test en",
        "extra": "extra",
        "login": "Login",
        "price": "Price:",
        "logout": "Log out",
        "resend": "Send again",
        "street": "Street and area code",
        "terms1": "terms and conditions",
        "terms2": "processing of personal data",
        "to_you": "Treat yourself",
        "verify": "Verify",
        "weight": "Weight:",
        "account": "Account",
        "in_cart": "in the cart",
        "removed": "Removed",
        "waiting": "Awaiting acceptance",
        "canceled": "Cancelled",
        "delivery": "Delivery",
        "link_acc": "Account",
        "personal": "Personally",
        "sms_code": "SMS code",
        "acc_phone": "Phone number:",
        "allergens": "Allergens:",
        "cart_send": "Send",
        "link_blog": "Blog",
        "meal_pack": "Food packaging",
        "my_orders": "My orders",
        "something": "something",
        "week_head": "MON - SUN",
        "agree_with": "I agree with",
        "cart_title": "Cart",
        "cart_total": "Total amount",
        "link_login": "Login",
        "to_address": "Delivery to address",
        "add_to_cart": "Add to cart",
        "link_orders": "My orders",
        "loading_app": "Loading...",
        "pay_waiting": "Waiting for payment to complete",
        "sure_logout": "Are you sure you want to log out?",
        "link_contact": "Contact",
        "login_action": "Log in",
        "meal_content": "Ingredients:",
        "order_status": "Order status",
        "phone_number": "Phone number",
        "cart_delivery": "Delivery",
        "delivery_type": "Choose your delivery method",
        "acc_error_save": "An error occurred while saving data.",
        "bottle_deposit": "Bottle/can deposit",
        "delivery_hours": "Delivery hours",
        "delivery_price": "Shipping cost",
        "no_chosen_food": "You don't have any food selected yet.",
        "pay_dont_close": "Please wait and do not close the application.",
        "sms_send_error": "Failed to send SMS",
        "current_offline": "We are currently offline, please try again later or call us.",
        "go_to_pick_meal": "Go to food selection",
        "meal_extra_pack": "Cover for the extra",
        "payment_options": "Payment options",
        "personal_pickup": "Personal pick-up",
        "verify_sms_code": "Verify SMS code",
        "address_delivery": "Delivery address",
        "order_send_error": "Failed to send order.",
        "sms_verify_error": "SMS verification failed",
        "window_autoclose": "This window will automatically close in",
        "acc_name_username": "Name and surname",
        "estimate_delivery": "Estimated delivery time is",
        "name_and_username": "Name and surname",
        "contact_restaurant": "Contact the restaurant",
        "no_delivery_option": "We do not offer delivery to this city/town.",
        "order_send_success": "Your order with number {id} has been successfully shipped.",
        "payment_and_summary": "Payment and order summary",
        "select_pic_up_place": "Select a personal pick-up point",
        "error_load_my_orders": "An error occurred loading orders",
        "waiting_for_delivery": "Received, awaiting delivery",
        "discover_without_login": "Browse menu without logging in",
        "use_other_phone_number": "Use a different phone number",
        "dont_close_until_payment_done": "Do not close the window until the payment is complete."
    }, "sk": {
        "or": "alebo",
        "sum": "Spolu",
        "card": "Platobná karta",
        "cash": "Hotovosť",
        "city": "Mesto / Obec",
        "date": "Dátum",
        "done": "Vybavená",
        "edit": "Upraviť",
        "free": "Zdarma",
        "next": "Ďalej",
        "note": "Poznámka k objednávke",
        "save": "Uložiť",
        "test": "test sk",
        "extra": "extra",
        "login": "Prihlásenie",
        "price": "Cena:",
        "logout": "Odhlásiť",
        "resend": "Odoslať znova",
        "street": "Ulica a popisné číslo",
        "terms1": "obchodnými podmienkami",
        "terms2": "spracovania osobných údajov",
        "to_you": "Doprajte si",
        "verify": "Overiť",
        "weight": "Hmotnosť:",
        "account": "Účet",
        "in_cart": "v košíku",
        "removed": "Odstránené",
        "waiting": "Čaká na prijatie",
        "canceled": "Zrušená",
        "delivery": "Doprava",
        "link_acc": "Účet",
        "personal": "Osobne",
        "sms_code": "SMS kód",
        "acc_phone": "Telefónne číslo:",
        "allergens": "Alergény:",
        "cart_send": "Odoslať",
        "link_blog": "Blog",
        "meal_pack": "Obal na jedlo",
        "my_orders": "Moje objednávky",
        "something": "niečo",
        "week_head": "PO - NE",
        "agree_with": "Súhlasím s",
        "cart_title": "Košík",
        "cart_total": "Celková suma",
        "link_login": "Prihlásenie",
        "to_address": "Doručenie na adresu",
        "add_to_cart": "Pridať do košíka",
        "link_orders": "Moje objednávky",
        "loading_app": "Načítavam...",
        "pay_waiting": "Čaká sa na dokončenie platby",
        "sure_logout": "Naozaj sa chcete odhlásiť?",
        "link_contact": "Kontakt",
        "login_action": "Prihlásiť",
        "meal_content": "Zloženie:",
        "order_status": "Stav objednávky",
        "phone_number": "Telefónne číslo",
        "cart_delivery": "Doprava",
        "delivery_type": "Vyberte si spôsob doručenia",
        "acc_error_save": "Nastala chyba pri ukladaní údajov",
        "bottle_deposit": "Záloha za nápoj",
        "delivery_hours": "Doručovacie hodiny",
        "delivery_price": "Cena dopravy",
        "no_chosen_food": "Zatiaľ nemáte žiadne jedlo",
        "pay_dont_close": "Prosím čakajte a nezatvárajte aplikáciu",
        "sms_send_error": "Nepodarilo sa odoslať SMS",
        "current_offline": "Momentálne sme offline, skúste neskôr alebo nám zavolajte.",
        "go_to_pick_meal": "Prejsť na výber jedál",
        "meal_extra_pack": "Obal na prílohu",
        "min_meal_amount": "Minimálny počet obejdnaných veci na túto adresu je {amount}",
        "min_valid_price": "Minimálna cena objednávky na túto adresu je {price} €. Cena sa počíta bez dopravy",
        "payment_options": "Možnosti platby",
        "personal_pickup": "Osobný odber",
        "verify_sms_code": "Overte SMS kód",
        "address_delivery": "Adresa doručenia",
        "order_send_error": "Nepodarilo sa odoslať objednávku",
        "sms_verify_error": "Nepodarilo sa overiť SMS",
        "window_autoclose": "Toto okno sa automaticky zatvorí do ",
        "acc_name_username": "Meno a priezvisko",
        "estimate_delivery": "Odhadovaný čas doručenia je",
        "name_and_username": "Meno a priezvisko",
        "contact_restaurant": "Kontaktujte prevádzku",
        "no_delivery_option": "Do tohto mesta / obce neponúkame doručenie",
        "order_send_success": "Vaša objednávka s číslom {id} bola úspenšne odoslaná",
        "payment_and_summary": "Platba a zhrnutie objednávky",
        "select_pic_up_place": "Vybrať odberné miesto",
        "error_load_my_orders": "Chyba pri načítaní objednávok",
        "waiting_for_delivery": "Prijatá, čaká na doručenie",
        "insert_valid_username": "Zadajte platné meno a priezvisko",
        "discover_without_login": "Prehliadať menu bez prihlásenia",
        "use_other_phone_number": "Použiť iné telefónne číslo",
        "dont_close_until_payment_done": "Nezatvárajte okno pokiaľ platba nebude hotová",
        "cart_remove_item": "Naozaj chcete odstrániť tento produkt z košíka?",
        "dayli_menu_pe": "Denné menu - Partizánske"
    }
}