import {defineStore} from 'pinia'
import {ref, reactive, computed} from "vue"
import {useRouter} from 'vue-router'
import {ROUTE_NAMES} from "@/router"
import useContentStore from "@/stores/contentStore"
import Supabase from "@/plugins/Supabase";
import {getHours, getISODay} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';
import usePluStore from "@/stores/pluStore";

const useSerStore = defineStore('userStore', () => {

    const user = ref({})

    const setUser = data => user.value = data

    const logout = async () => {
        user.value = {}
        const {error} = await Supabase.auth.signOut()
        if (error) {
            console.error('Error logging out:', error.message)
            alert('Error logging out')
        }
    }

    const getUser = () => ({...user.value})

    const hasUser = () => isLogged.value

    const isLogged = computed(() => Object.keys(user.value).length > 0)

    return {
        user,
        isLogged,
        setUser,
        logout,
        getUser,
        hasUser
    }

})

export default useSerStore