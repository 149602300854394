<template>
  <MasterLayout>

    <div class="flex  flex-col  h-full w-full">

      <div class="text-white mt-8 flex items-center font-bold text-4xl">
        <span class="mr-4 italic">
           {{ $t('my_orders') }}
        </span>
      </div>

      <Loader v-if="loading" class="w-[230px] h-auto mx-auto"/>

      <div class="flex flex-1 mt-4 overflow-y-scroll flex-col">
        <div v-for="order in orders"
             :key="order.id"
             class="border relative px-3 pt-2 pb-4 border-grey-0 mb-4 rounded-tl-xl rounded-br-xl">

          <div class="flex items-center justify-between">
            <div class="text-white  text-lg">
              <span class="font-medium">
                 ID:
              </span>
            </div>
            <span class="text-secondary-0 underline text-lg font-bold">
              {{ order.pluOrderId }}
            </span>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-white  text-lg">
              <span class="font-medium">
                 {{ $t('date') }}
              </span>
            </div>
            <span class="text-secondary-0 text-lg font-bold">
            {{ parseIsoDate(order.createdAt) }}
          </span>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-white  text-lg">
              <span class="font-medium">
                 {{ $t('order_status') }}
              </span>
            </div>
            <span class="text-secondary-0 text-lg font-bold">
            <template v-if="order.status === ORDER_STATUS.NEW">
               {{ $t('waiting') }}
            </template>
            <template v-else-if="order.status === ORDER_STATUS.CONFIRMED">
              <span class="text-info-0">
                 {{ $t('waiting_for_delivery') }}
              </span>
            </template>
            <template v-else-if="order.status === ORDER_STATUS.CANCELED">
              <span class="text-danger-0">
                 {{ $t('canceled') }}
              </span>
            </template>
            <template v-else-if="order.status === ORDER_STATUS.DONE">
              <span class="text-success-0">
                 {{ $t('done') }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </span>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-white  text-lg">
              <span class="font-medium">
                 {{ $t('price') }}
              </span>
            </div>
            <span class="text-secondary-0 text-lg font-bold">
            {{ order.finalPrice }} €
          </span>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-white  text-lg">
              <span class="font-medium">
                 {{ $t('delivery') }}
              </span>
            </div>
            <span class="text-secondary-0 text-lg font-bold">
           <template v-if="order.deliveryType === 'personal'">
            {{ $t('personal') }}: {{ order.deliveryAddress === 'BO' ? 'Bošany' : 'Partizánske' }}
           </template>
            <template v-else>
              {{ order.deliveryAddress }}
            </template>
          </span>
          </div>

          <hr class="my-1 opacity-25 border-white">

          <template v-for="(item,i) in (order?.orderItems ?? [])" :key="i">

            <div class="text-white flex justify-between">
              <div class="space-x-3 flex items-center">
                <span class="text-lg opacity-95">{{ item.title }}</span>
                <span class=" text-lg text-secondary-0 opacity-75">
                  <span class="font-bold">{{item.quantity }}</span>  x</span>
              </div>
              <span class="text-white font-light">{{ Number(item.price * item.quantity).toFixed(2) }} €</span>
            </div>

            <div class="pl-4">
              <div v-for="(extraItem,_) in item.extra" :key="`extra-${i}-${_}`" class="text-white flex justify-between">
                <div class="space-x-3 flex items-center">
                  <span class="text-lg opacity-95">{{ extraItem.title }}</span>
                  <span class=" text-lg text-secondary-0 opacity-75">
                    <span class="font-bold">{{extraItem.quantity }}</span> x</span>
                </div>
                <span class="text-white font-light">{{  Number(extraItem.price * extraItem.quantity).toFixed(2) }} €</span>
              </div>
            </div>

            <hr v-if="i < (order.orderItems ?? []).length - 1" class="my-1 opacity-25 border-white">

          </template>

          <!--          <pre>-->
          <!--            {{ order }}-->
          <!--          </pre>-->

        </div>
      </div>

    </div>

  </MasterLayout>
</template>

<script setup>
import {ref, onUnmounted} from "vue";
import {useRouter} from "vue-router";
import useSerStore from "@/stores/userStore";
import MasterLayout from "@/layouts/MasterLayout.vue";
import Supabase from "@/plugins/Supabase";
import {format, parseISO} from 'date-fns';
import {ORDER_STATUS} from "@/stores/cartStore";
import Loader from "@/components/Loader.vue";
import {useI18n} from 'vue-i18n'

const router = useRouter()
const userStore = useSerStore();
const user = userStore.getUser()
const {t} = useI18n()

const loading = ref(false)
const orders = ref([])

let realTimeChannel = null

const parseIsoDate = date => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'dd.MM.yyyy HH:mm');
}
const loadMyOrders = async () => {

  loading.value = true
  // const {data, error} = await Supabase.from('v2_Hajcer_App_App_Orders').select('*').eq('userId', user.id)
  const {
    data,
    error
  } = await Supabase.from('v2_Hajcer_App_App_Orders')
      .select('* , orderItems:v2_Hajcer_App_App_Orders_Items ( * ) ')
      .eq('userId', user.id)
      .order('createdAt', {ascending: false})

  loading.value = false
  if (error) {
    alert(t('error_load_my_orders'))
    return
  }

  orders.value = data

  if (!realTimeChannel) {
    realTimeChannel = Supabase.channel('v2_Hajcer_App_App_Orders-REAL_TIME-' + user.id)
        .on(
            'postgres_changes',
            {
              event: 'UPDATE',
              schema: 'public',
              table: 'v2_Hajcer_App_App_Orders',
              filter: 'userId=eq.' + user.id,
            },
            async (payload) => {
              await loadMyOrders()
            }
        )
        .on(
            'postgres_changes',
            {
              event: 'INSERT',
              schema: 'public',
              table: 'v2_Hajcer_App_App_Orders',
              filter: 'userId=eq.' + user.id,
            },
            async (payload) => {
              await loadMyOrders()
            }
        )
        .subscribe()
  }

}

loadMyOrders()

onUnmounted(() => {
  if (realTimeChannel) {
    realTimeChannel.unsubscribe()
    realTimeChannel = null
  }
})


</script>