<template>
  <div class="flex items-center w-full  justify-between">
    <Box @click="goTo(1)" :title="props.steps[0]" :class="value === 1 ? 'step-active':'step-done'"/>
<!--    <div class=" h-[4px] flex-grow  mx-2" :class="value === 1 ? 'bg-white opacity-15 ':'bg-secondary-0'"></div>-->
    <Box @click="goTo(2)" :title="props.steps[1]"
         :class="value === 1 ? 'step-inactive': value === 2 ?'step-active':'step-done'"/>
<!--    <div class=" h-[4px] flex-grow  mx-2" :class="value !== 3 ? 'bg-white opacity-15':'bg-secondary-0'"></div>-->
    <Box @click="goTo(3)" :title="props.steps[2]" :class="[1,2].includes(value) ? 'step-inactive':'step-active'"/>
  </div>
</template>

<script setup>
import {computed} from "vue"
import Box from "@/components/stepper/Box.vue"

const props = defineProps({
  steps: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
})

const goTo = step => value.value = step

</script>