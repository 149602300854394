<template>

  <div class="flex flex-col  max-h-full h-full px-4 ">

    <template v-if="props.hasNavigationMenu">
      <AppMainNavigation/>
      <div class="w-full pb-4 mt-[8px] justify-between flex items-center">

        <font-awesome-icon v-if="!layoutStore.sideMenuOpened"
                           @click="layoutStore.openSideMenu()"
                           :icon="['fas','bars-staggered']"
                           class="text-white text-4xl"/>
        <div v-else></div>

        <div @click="goToPickDelivery"
             class="h-[52px] flex rounded-full items-center mr-4 pl-5 pr-[60px] rounded-l-full relative aa bg-secondary-0">
          <div class="font-bold text-primary-0 ml-2 text-lg">
            {{ deliveryStore.deliveryPlaceSymbol === 'BO' ? 'Bošany' : 'Partizánske' }}
          </div>
          <div class="h-[52px] z-10 w-[44px] border-primary-0 rounded-full bg-primary-0 absolute right-[-8px]"></div>
          <div @click.stop.prevent="goToCart"
               class=" w-[52px] h-[52px] z-20 absolute top-[0] right-[-20px] bg-primary-0 border-secondary-0 rounded-full border flex items-center justify-center ">
            <font-awesome-icon :icon="['fal','basket-shopping']" class="text-white text-lg"/>
          </div>

        </div>

      </div>
    </template>

    <slot name="prepend"/>

    <div class="flex-1 relative  flex flex-col max-h-full   items-stretch"
         :class="{'overflow-y-hidden': props.hiddenOverflow}"
    >
      <slot/>
    </div>

  </div>
</template>

<script setup>
import {ref, onMounted, nextTick, onUnmounted, watch, computed} from 'vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AppMainNavigation from "@/components/layout/AppMainNavigation.vue";
import {useRouter} from "vue-router";
import {ROUTE_NAMES} from "@/router";
import useLayoutStore from "@/stores/layoutStoreStore";
import useContentStore from "@/stores/contentStore";
import useDeliveryStore from "@/stores/deliveryStore";

const props = defineProps({
  hasNavigationMenu: {
    type: Boolean,
    default: true
  },
  sun: {
    type: String,
    default: 'lt-to-rb'
  },
  hiddenOverflow: {
    type: Boolean,
    default: true
  }
})

const layoutStore = useLayoutStore()
const contentStore = useContentStore()
const deliveryStore = useDeliveryStore()

const router = useRouter()
const goToPickDelivery = () => router.push({
  name: ROUTE_NAMES.PICK_DELIVERY_VIEW
})

const goToCart = () => router.push({
  name: ROUTE_NAMES.CART_VIEW
})

watch(() => props.sun, (value) => {
  nextTick(() => {
    const el = document.getElementById('appWrapper')
    if (!el) return
    el.classList.remove('lt-to-rb')
    el.classList.remove('tr-to-bl')
    if (value && value !== '') el.classList.add(value)
  })
}, {immediate: true, flush: 'post'})

</script>

<style scoped lang="scss">
//.lt-to-rb {
//  background-image: radial-gradient(
//          circle at -5% -5%,
//          rgba(251, 182, 101, 0.5) 0%,
//          rgba(251, 182, 101, 0.4) 3%,
//          rgba(251, 182, 101, 0) 25%
//  );
//}
//
//.tr-to-bl {
//  background-image: radial-gradient(
//          circle at 105% -5%,
//          rgba(251, 182, 101, 0.5) 0%,
//          rgba(251, 182, 101, 0.4) 3%,
//          rgba(251, 182, 101, 0) 25%
//  );
//}
</style>