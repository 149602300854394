import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

// solid
import {
    faBarsStaggered as fasBarsStaggered,
    faTimes as fasTimes,
    faStore as falStore,
    faTruckUtensils as falTruckUtensils,
    faAngleLeft as fasAngleLeft,
    faCheck as fasCheck,
    faX as fasX,
    faPlus as fasPlus,
    faMinus as fasMinus,
    faList as fasList,
    faLocationDot as fasLocationDot,
    faWallet as fasWallet,
    faCreditCard as fasCreditCard,
    faCircleInfo as fasCircleInfo,
    faArrowLeft as fasArrowLeft,
    faDoorOpen as fasDoorOpen,
} from '@fortawesome/pro-solid-svg-icons'

import {
    faAngleRight as falAngleRight,
    faAngleLeft as falAngleLeft,
    faUser as falUser,
    faBasketShopping as falCartShopping,
    faPhone as falPhone,
    faBlog as falBlog,
    faHome as falHome,
    faCheck as falCheck,
    faX as falX,
    faTurkey as falTurkey,
} from '@fortawesome/pro-light-svg-icons'

import {
    faListDropdown as fatListDropdown,
} from '@fortawesome/pro-thin-svg-icons'

import {
    faBurger as fadBurger,
    faPizzaSlice as fadPizzaSlice,
    faCakeSlice as fadCakeSlice,
    faPotFood as fadPotFood,
    faPanFood as fadPanFood,
    faPlateUtensils as fadPlateUtensils,
    faSpinnerThird as fadSpinnerThird,
} from '@fortawesome/pro-duotone-svg-icons'


import {} from '@fortawesome/free-brands-svg-icons'

// Install font awesome pack
export default app => {

    library.add(falTurkey)
    library.add(fatListDropdown)

    library.add(fadPlateUtensils)
    library.add(fadBurger)
    library.add(fadPizzaSlice)
    library.add(fadCakeSlice)
    library.add(fadPotFood)
    library.add(fadPanFood)
    library.add(fadSpinnerThird)

    library.add(fasDoorOpen)
    library.add(fasArrowLeft)
    library.add(fasCircleInfo)
    library.add(fasWallet)
    library.add(fasCreditCard)
    library.add(fasLocationDot)
    library.add(fasList)
    library.add(fasMinus)
    library.add(fasPlus)
    library.add(fasAngleLeft)
    library.add(fasBarsStaggered)
    library.add(fasX)
    library.add(fasCheck)
    library.add(fasTimes)

    library.add(falStore)
    library.add(falTruckUtensils)
    library.add(falCheck)
    library.add(falX)

    library.add(falHome)
    library.add(falBlog)
    library.add(falPhone)
    library.add(falAngleRight)
    library.add(falAngleLeft)
    library.add(falUser)
    library.add(falCartShopping)


    app.component('font-awesome-icon', FontAwesomeIcon)

}