<template>
  <div class="border border-grey-0 mt-4 rounded-br-2xl rounded-tl-2xl  px-4 py-3 flex items-center  "
       @click="deliveryStore.setDeliveryType(props.type)"
       :class="[isSelected ? 'bg-secondary-0 border-secondary-0 flex justify-between' : 'bg-primary-0']"
  >
    <div class="flex  items-center">
      <div class="w-[32px]">
        <font-awesome-icon :icon="props.icon" class=" text-xl  "
                           :class="[isSelected ? 'text-primary-0':'text-white']"/>
      </div>
      <div class="font-roboto text-lg font-medium " :class="[isSelected ? ' text-primary-0':'text-white']">
        <slot/>
      </div>
    </div>
    <font-awesome-icon :icon="['fal','check']" class=" text-lg text-primary-0"/>
  </div>
</template>

<script setup>
import {computed} from "vue"
import useDeliveryStore from "@/stores/deliveryStore"

const props = defineProps({
  icon: {},
  type: {}
})

const deliveryStore = useDeliveryStore()
const isSelected = computed(() => deliveryStore.selectedDeliveryType === props.type)

</script>