import {ROUTE_NAMES} from "@/router/index"
import useDeliveryStore from "@/stores/deliveryStore"
// import useContentStore from "@/stores/contentStore";
import Supabase from "@/plugins/Supabase";
import useSerStore from "@/stores/userStore";

const beforeEachGuard = (async (to, from, next) => {

    const deliveryStore = useDeliveryStore()
    // const contentStore = useContentStore()
    const userStore = useSerStore()

    const {data: dataSession} = await Supabase.auth.getSession()
    const sessionUser = dataSession.session?.user ?? null
    console.log('===> sessionUser', sessionUser)
    if (sessionUser && !userStore.hasUser()) {
        console.log('ns')
        userStore.setUser(sessionUser)
    }

    // go to pick delivery view if no delivery type is selected
    if (!deliveryStore.deliveryIsSet && to.name !== ROUTE_NAMES.PICK_DELIVERY_VIEW) return next({name: ROUTE_NAMES.PICK_DELIVERY_VIEW})

    if (to.name === ROUTE_NAMES.LOGIN_VIEW && sessionUser) return next({name: ROUTE_NAMES.HOME_VIEW})
    if (to.name === ROUTE_NAMES.CART_VIEW && !sessionUser) return next({name: ROUTE_NAMES.LOGIN_VIEW})

    next()

})

export default beforeEachGuard

