import {defineStore} from 'pinia'
import {ref, computed} from "vue"
import {formatInTimeZone} from 'date-fns-tz';
import useDeliveryStore from "@/stores/deliveryStore";
import {useI18n} from "vue-i18n";

export const ORDER_STATUS = {
    WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
    NEW: 'NEW',
    CONFIRMED: 'CONFIRMED',
    CANCELED: 'CANCELED',
    DONE: 'DONE'
}

/**
 * Calculates the delivery duration based on the current time and day in the Europe/Prague timezone.
 * @returns {number} The delivery duration multiplier.
 */
const getDeliveryDuration = () => {
    // Define the target timezone
    const timeZone = 'Europe/Prague';

    // Get the current date and time
    const now = new Date();

    // Extract the current hour (0-23) in the specified timezone
    const hour = Number(formatInTimeZone(now, timeZone, 'H'));

    // Get the ISO day of the week (1 = Monday, ..., 7 = Sunday) in the specified timezone
    const dayOfWeek = Number(formatInTimeZone(now, timeZone, 'i'));

    // Determine delivery duration based on the day and time
    if (dayOfWeek === 5 || dayOfWeek === 6) { // Friday or Saturday
        if (hour >= 11 && hour < 17) {
            return 4;
        } else if (hour >= 17 && hour < 20) {
            return 8;
        } else if (hour >= 20 && hour < 21) {
            return 6;
        } else {
            return 3;
        }
    } else if (dayOfWeek === 7) { // Sunday
        if (hour >= 11 && hour < 17) {
            return 4;
        } else if (hour >= 17 && hour < 20) {
            return 6;
        } else {
            return 3;
        }
    } else { // Monday to Thursday
        return (hour > 17 && hour < 20) ? 4 : 3;
    }
};


const useCartStore = defineStore('cartStore', () => {

    const {t} = useI18n()
    const deliveryStore = useDeliveryStore()

    const itemsInCart = ref([])
    const cartItems = computed(() => itemsInCart.value)
    const cartIsEmpty = computed(() => itemsInCart.value.length === 0)
    const clearCart = () => {
        itemsInCart.value = []
    }

    const addProductToCart = ({product, removeFromMeal, extra}) => {
        console.log('addProductToCart()', {
            ...{product, removeFromMeal, extra}
        })
        itemsInCart.value.push({
            ...product,
            removeFromMeal,
            quantity: 1,
            extra
        })
        console.log('itemsInCart', itemsInCart.value, itemsInCart.value.length - 1)
        // return index
        return {
            index: itemsInCart.value.length - 1
        }
    }

    const removeFromCart = (index) => {
        if (!confirm(t('cart_remove_item'))) return
        itemsInCart.value.splice(index, 1)
    }

    const calcMealPrice = (meal) => {

        console.log('meal', meal)
        const hasPackage = !!meal.foodPackaging
        const packagePrice = hasPackage ? meal.foodPackaging.pluPrice : 0
        const productPrice = meal.pluItem.pluPrice

        const hasMealExtra = !!meal.extra && Object.keys(meal.extra).length > 0
        const mealExtraPrice = calcSingleMealExtraItemPrice(meal.extra, meal.quantity)

        const baseMealPrice = Number(productPrice).toFixed(2)
        const price = Number(((productPrice + packagePrice) * meal.quantity) + mealExtraPrice).toFixed(2)
        const finalPrice = Number(price).toFixed(2)

        return {
            baseMealPrice,
            hasPackage,
            packagePrice,
            productPrice,
            mealExtraPrice,
            price,
            finalPrice,
            hasMealExtra
        }

    }

    const calcSingleMealExtraItemPrice = (mealExtra, qty) => {
        let count = 0
        const keys = Object.keys(mealExtra)
        for (let i = 0; i < keys.length; i++) {
            const extraProduct = mealExtra[keys[i]].product
            const quantity = mealExtra[keys[i]].quantity
            count += (extraProduct.pluItem.pluPrice * quantity) * qty
            if (extraProduct.foodPackaging) {
                count += (extraProduct.foodPackaging.pluPrice * quantity) * qty
            }
            if (extraProduct.deposit) {
                count += (extraProduct.deposit.pluPrice * quantity) * qty
            }
        }
        return count
    }

    const sumAllCartItems = computed(() => {

        let count = 0
        for (let i = 0; i < itemsInCart.value.length; i++) {
            const item = itemsInCart.value[i]
            const {finalPrice} = calcMealPrice(item)
            count += Number(finalPrice)
        }

        return Number(count).toFixed(2)

    })

    const sumAllCartItemsPlusDelivery = computed(() => {

        return Number(Number(sumAllCartItems.value) + Number(deliveryStore.deliveryPrice)).toFixed(2)

    })

    const deliveryTimeInMinutes = computed(() => getDeliveryDuration() * 15)

    const deliveryError = computed(() => {

        if (deliveryStore.isPersonalPickUp) return false
        if (!deliveryStore.addressDeliveryIsSet) return false

        if (deliveryStore.addressDelivery.city) {
            const {minOrderPrice, minOrderAmount} = deliveryStore.addressDelivery.city
            if (Number(minOrderPrice) > Number(sumAllCartItems.value)) return t('min_valid_price', {price: minOrderPrice})
            if (Number(minOrderAmount) > itemsInCart.value.length) return t('min_meal_amount', {amount: minOrderAmount})
            // if (Number(minOrderAmount) > itemsInCart.value.length) return 'Minimálny počet obejdnaných veci na túto adresu je ' + minOrderAmount
        }
        return false
    })

    return {
        addProductToCart,
        calcMealPrice,
        removeFromCart,
        clearCart,
        sumAllCartItems,
        cartItems,
        itemsInCart,
        cartIsEmpty,
        deliveryTimeInMinutes,
        deliveryError,
        sumAllCartItemsPlusDelivery
    }

})

export default useCartStore