import {Capacitor} from '@capacitor/core'
import {SafeArea} from 'capacitor-plugin-safe-area'
import {ref, computed} from 'vue'

export const platform = Capacitor.getPlatform().toLowerCase()
export const topSafeArea = ref(0);
export const bottomSafeArea = ref(0);
export const getTopPlusBottomSafeArea = computed(() => topSafeArea.value + bottomSafeArea.value);

export const safeAreaStyle = ref({
    maxHeight: '100%',
})

export default async app => {

    if (platform === 'web') {
        // document.body.style.overflowY = 'auto'
        return console.log('SafeArea plugin is not available on the web platform')
    }

    /*
     * Safe area plugin for Capacitor
     * overrides the default safe area values, by the real safe area values of current device
     */
    const setInsetsToAppStyleMaxHeight = insets => {
        if (platform === 'ios') {
            const appEl = document.getElementById('app')
            const maxHeight = `calc(100vh - ${insets.top + insets.bottom}px)`
            const height = `calc(100vh - ${insets.top + insets.bottom}px)`
            topSafeArea.value = insets.top
            bottomSafeArea.value = insets.bottom
            appEl.style.maxHeight = maxHeight
            appEl.height = height
            safeAreaStyle.value = {
                maxHeight: maxHeight,
            }
        }
    }

    SafeArea.getSafeAreaInsets().then(({insets}) => {
        setInsetsToAppStyleMaxHeight(insets)
    })

    // when safe-area changed
    await SafeArea.addListener('safeAreaChanged', data => {
        const {insets} = data
        // for (const [key, value] of Object.entries(insets)) {
        //     if (key === 'top') topSafeArea.value = insets.top
        //     if (key === 'bottom') bottomSafeArea.value = insets.bottom
        //     console.log('key', key, 'value', value)
            // document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`,)
        // }
        setInsetsToAppStyleMaxHeight(insets)
    })

}
