import {defineStore} from 'pinia'
import {ref, reactive, computed, nextTick} from "vue"
import useLayoutStore from "@/stores/layoutStoreStore"
import router from "@/router";
import {ROUTE_NAMES} from "@/router"
import Supabase from "@/plugins/Supabase";
import useDeliveryStore from "@/stores/deliveryStore";


const useContentStore = defineStore('contentStore', () => {

    const deliveryStore = useDeliveryStore()

    const appContentData = ref([])
    const appContentLoading = ref(true)
    const loadAppContent = async () => {

        const {data: appContent, error} = await Supabase
            .from('v2_Hajcer_App_Categories')
            .select(`
        *,
        items:v2_Hajcer_App_Products (
            *,
            pluItem:v2_Hajcer_PluItems!v2_Hajcer_App_Products_pluItemId_fkey (* ),
            foodPackaging:v2_Hajcer_PluItems!v2_Hajcer_App_Products_foodPackaging_fkey (*  )
        ),
        mealExtra1:v2_Hajcer_App_Products_Extra!v2_Hajcer_App_Categories_mealExtraId_1_restaurantPlace_fkey (
           *,
            products:v2_Hajcer_App_Products_Extra_Products (
                *,
                product:v2_Hajcer_App_Products (
                    *,
                    pluItem:v2_Hajcer_PluItems!v2_Hajcer_App_Products_pluItemId_fkey (*  ),
                    foodPackaging:v2_Hajcer_PluItems!v2_Hajcer_App_Products_foodPackaging_fkey (* ),
                    deposit:v2_Hajcer_PluItems!v2_Hajcer_App_Products_depositId_fkey (* )
                )
            )
        ),
        mealExtra2:v2_Hajcer_App_Products_Extra!v2_Hajcer_App_Categories_mealExtraId_2_restaurantPlace_fkey (
            *,
            products:v2_Hajcer_App_Products_Extra_Products (
                *,
                product:v2_Hajcer_App_Products (
                    *,
                    pluItem:v2_Hajcer_PluItems!v2_Hajcer_App_Products_pluItemId_fkey (*  ),
                    foodPackaging:v2_Hajcer_PluItems!v2_Hajcer_App_Products_foodPackaging_fkey (* ),
                    deposit:v2_Hajcer_PluItems!v2_Hajcer_App_Products_depositId_fkey (* )
                )
            )
        ),
        mealExtra3:v2_Hajcer_App_Products_Extra!v2_Hajcer_App_Categories_mealExtraId_3_restaurantPlace_fkey (
           *,
            products:v2_Hajcer_App_Products_Extra_Products (
                *,
                product:v2_Hajcer_App_Products (
                    *,
                    pluItem:v2_Hajcer_PluItems!v2_Hajcer_App_Products_pluItemId_fkey (*  ),
                    foodPackaging:v2_Hajcer_PluItems!v2_Hajcer_App_Products_foodPackaging_fkey (* ),
                    deposit:v2_Hajcer_PluItems!v2_Hajcer_App_Products_depositId_fkey (* )
                )
            )
        )
    `)
            .eq('active', true)
            .order('order', {ascending: true});


        if (error) {
            alert('Error loading app content')
        }

        console.log('[!!]', {appContent, error})

        appContentData.value = appContent
        console.log('categories.value', categories.value)
        appContentLoading.value = false


        // // map cateogires
        // const mappedCategories = appContent.filter(c => c.restaurantPlace === deliveryStore.deliveryPlaceSymbol).map(category => {
        //     return {
        //         ...category
        //     }
        // })
        //
        // categories.value = mappedCategories
        // await setSelectedCategoryId(categories.value[0].id)
        //
        // console.log('categories.value', categories.value)
        //
        // appContentLoading.value = false

    }

    const mapContentMapped = ref(false)
    const mapAppContent = async () => {
        console.log('mapAppContent()', deliveryStore.deliveryPlaceSymbo)
        const mappedCategories = appContentData.value.filter(c => c.restaurantPlace === deliveryStore.deliveryPlaceSymbol).map(category => {
            return {
                ...category
            }
        })
        categories.value = mappedCategories
        await setSelectedCategoryId(categories.value[0].id)
        mapContentMapped.value = true
    }

    const layoutStore = useLayoutStore()
    /*
     * Categories
     */
    const _selectedCategoryId = ref(null)
    const selectedCategoryId = computed(() => _selectedCategoryId.value)
    _selectedCategoryId.value = 1

    const setSelectedCategoryId = async (id) => {
        _selectedCategoryId.value = id
        layoutStore.closeSideMenu()
        await router.push({name: ROUTE_NAMES.HOME_VIEW})
    }

    const itemsInCategory = ref([])
    const loadCategoryData = async () => {
        itemsInCategory.value = []
        await nextTick()

        if (selectedCategory.value?.items) {
            itemsInCategory.value = [...selectedCategory.value.items]
            return
        }

        // itemsInCategory.value = Array.from({length: 12}, (_, i) => i)
    }

    const x = [
        {
            id: 1,
            name: 'Burger',
            icon: ['fad', 'burger'],
            title: 'Chuť burgera',
            subtitle: 'ako žiadna iná'
        },
        {
            id: 2,
            name: 'Pizza',
            icon: ['fad', 'pizza-slice'],
            title: 'Chuť pizze',
            subtitle: 'ako v taliansku'
        },
        {
            id: 3,
            name: 'Poke',
            icon: ['fad', 'pot-food'],
            title: 'Chuť poke',
            subtitle: 'ako na Havaji'
        },
        {
            id: 4,
            name: 'Meals',
            icon: ['fad', 'pan-food'],
            title: 'Chuť jedál',
            subtitle: 'ako doma'
        },
        {
            id: 5,
            name: 'Dessert',
            icon: ['fad', 'cake-slice'],
            title: 'Chuť dezertov',
            subtitle: 'ako v cukrárni'
        }
    ]
    const categories = ref([])
    // const categories = ref([...x])

    const selectedCategory = computed(() => categories.value.find(category => category.id === selectedCategoryId.value))
    const selectedCategoryIndex = computed(() => categories.value.findIndex(category => category.id === selectedCategoryId.value))
    const nextCategoryId = computed(() => {
        const index = categories.value.findIndex(category => category.id === selectedCategoryId.value)
        return categories.value[index + 1]?.id ?? null
    })
    const prevCategoryId = computed(() => {
        const index = categories.value.findIndex(category => category.id === selectedCategoryId.value)
        return categories.value[index - 1]?.id ?? null
    })
    const isSelectedFirstCategory = computed(() => selectedCategoryId.value === categories.value[0]?.id)


    return {
        loadAppContent,
        categories,
        selectedCategoryId,
        selectedCategory,
        isSelectedFirstCategory,
        nextCategoryId,
        prevCategoryId,
        selectedCategoryIndex,
        itemsInCategory,
        setSelectedCategoryId,
        loadCategoryData,
        appContentLoading,
        mapContentMapped,
        mapAppContent
    }


})

export default useContentStore