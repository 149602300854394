<template>
  <div class="text-white flex justify-between mt-2">
    <div class="space-x-3 flex items-center">
      <span class="text-lg opacity-95">{{ props.title }}</span>
      <span class=" text-lg text-secondary-0 opacity-75"><span class="font-bold">{{ props.quantity }}</span> ks</span>
    </div>
    <span class="text-white font-light">{{ finalPrice }} €</span>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  price: {
    type: Number,
    required: true
  },
  quantity: {
    type: Number,
    default: 1
  }
})

const finalPrice = computed(() => Number(props.price).toFixed(2))
// const finalPrice = computed(() => Number(props.price * props.quantity).toFixed(2))

</script>