import Supabase from "@/plugins/Supabase";
import FontAwesome from "@/plugins/FontAwesome";
import SafeArea from './capacitor/SafeArea'
import AppNotifications from './capacitor/Notifications'
import l18m from "@/plugins/l18m";
import {appTexts} from "@/content/lists";

export default async app => {

    app.provide('Supabase', Supabase)

    // const {data, error} = await Supabase.from('v2_Hajcer_App_Settings').update({
    //     texts: appTexts
    // }).eq('id', 'a1e147eb-f5a3-43db-af4c-39913302383d')
    // console.log('texts updated', data, error)


    l18m(app)
    FontAwesome(app)
    SafeArea(app)
    AppNotifications(app)

}