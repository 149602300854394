import {defineStore} from 'pinia'
import {ref, reactive, computed} from "vue"
import Supabase from "@/plugins/Supabase";
import {getHours, parseISO, getMinutes} from 'date-fns';
import {toZonedTime,} from 'date-fns-tz';
import {differenceInMinutes} from 'date-fns';
import {i18n} from "@/plugins/l18m";

const timeZone = 'Europe/Bratislava'; // Časová zóna, ktorú chcete použiť
let realTimeChannel = null

const usePluStore = defineStore('pluStore', () => {

    const loading = ref(false)

    const appSettings = reactive({
        freeDeliveryActive: false,
        appIsActiveBo: false,
        appIsActivePe: false,
        deliveryFromBo: new Date(),
        deliveryToBo: new Date(),
        deliveryFromPe: new Date(),
        deliveryToPe: new Date(),
        pluIpBO: null,
        lastSuccessPingBo: null,
        pluIpBE: null,
        lastSuccessPingPe: null,
    })

    const loadAppSettings = async () => {

        loading.value = true
        const {data, error} = await Supabase
            .from('v2_Hajcer_App_Settings')
            .select('*')
            .single()

        if (error) {
            alert('Error communicating with PLU')
            return
        }

        const keys = Object.keys(appSettings)
        for (const key of keys) {
            appSettings[key] = data[key]
        }

        try {
            i18n.global.setLocaleMessage('sk', {...i18n.global.messages.value.sk, ...data.texts.sk});
            i18n.global.setLocaleMessage('cz', {...i18n.global.messages.value.cz, ...data.texts.cz});
            i18n.global.setLocaleMessage('de', {...i18n.global.messages.value.de, ...data.texts.de});
            i18n.global.setLocaleMessage('en', {...i18n.global.messages.value.en, ...data.texts.en});
        } catch (e) {
            console.error('Error setting i18n', e)
        }

        await startLoadRealTime()

        loading.value = false
    }

    const startLoadRealTime = async () => {

        realTimeChannel = Supabase.channel('v2_Hajcer_App_Settings-REAL_TIME')
            .on(
                'postgres_changes',
                {event: 'UPDATE', schema: 'public', table: 'v2_Hajcer_App_Settings'},
                (payload) => {
                    console.log('App settings changed', payload)
                    // Object.assign(appSettings, payload.new)
                    const keys = Object.keys(appSettings)
                    for (const key of keys) {
                        appSettings[key] = payload.new[key]
                    }
                }
            )
            .subscribe()

    }

    const stopLoadRealTime = async () => realTimeChannel.unsubscribe()

    const restaurantIsOpened = ({from, to}) => {
        if (!from || !to) return false

        // Získaj aktuálny čas v zóne
        const now = new Date();
        const zonedTime = toZonedTime(now, timeZone);
        const currentHours = getHours(zonedTime);
        const currentMinutes = getMinutes(zonedTime);

        // Predpokladám, že appSettings.deliveryFromBo a appSettings.deliveryToBo sú v ISO formáte
        const fromDate = parseISO(from);
        const toDate = parseISO(to);

        const fromHours = getHours(fromDate);
        const fromMinutes = getMinutes(fromDate);
        const toHours = getHours(toDate);
        const toMinutes = getMinutes(toDate);

        // Funkcia na porovnanie času
        const isTimeInRange = (currentHours, currentMinutes, fromHours, fromMinutes, toHours, toMinutes) => {
            const currentTotalMinutes = currentHours * 60 + currentMinutes;
            const fromTotalMinutes = fromHours * 60 + fromMinutes;
            const toTotalMinutes = toHours * 60 + toMinutes;

            return currentTotalMinutes >= fromTotalMinutes && currentTotalMinutes < toTotalMinutes;
        };

        return isTimeInRange(currentHours, currentMinutes, fromHours, fromMinutes, toHours, toMinutes);

    }

    const isBoOpened = computed(() => restaurantIsOpened({
        from: appSettings.deliveryFromBo,
        to: appSettings.deliveryToBo
    }));

    const isPeOpened = computed(() => restaurantIsOpened({
        from: appSettings.deliveryFromPe,
        to: appSettings.deliveryToPe
    }));

    const pingSuccess = timestamp => {
        const timeZone = "Europe/Prague";

        // Parse timestamp as UTC
        const utcDate = parseISO(timestamp);

        // Convert to Prague timezone
        const pragueTime = toZonedTime(utcDate, timeZone);

        // Get the current time in Prague timezone
        const now = new Date();
        const nowInPrague = toZonedTime(now, timeZone);

        // Calculate the difference in minutes
        const minutesDifference = differenceInMinutes(nowInPrague, pragueTime);

        // Return true if the timestamp is within the last 10 minutes
        return minutesDifference <= 12;
    }

    const pingPeSuccess = computed(() => pingSuccess(appSettings.lastSuccessPingPe))
    const pingBoSuccess = computed(() => pingSuccess(appSettings.lastSuccessPingBo))

    return {
        isBoOpened,
        isPeOpened,
        appSettings,
        loading,
        pingPeSuccess,
        pingBoSuccess,
        loadAppSettings,
        startLoadRealTime,
        stopLoadRealTime
    }

})

export default usePluStore