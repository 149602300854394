<template>
  <div @click="linkClicked" class="flex space-x-4 items-center">
    <div class="w-[42px]">
      <font-awesome-icon :icon="props.icon" class=" text-2xl text-secondary-0"/>
    </div>
    <span class="font-normal font-roboto text-white">
      <slot/>
    </span>
  </div>
</template>

<script setup>
import {Browser} from '@capacitor/browser';
import {useRouter} from "vue-router";
import useLayoutStore from "@/stores/layoutStoreStore";

const props = defineProps({
  icon: {
    type: Array,
    required: true
  },
  isExternal: {
    type: Boolean,
    default: false
  },
  route: {
    type: String,
    default: ''
  }
})

const router = useRouter()
const layoutStore = useLayoutStore()

const linkClicked = async () => {
  layoutStore.closeSideMenu()

  if (props.isExternal) {
    await Browser.open({url: props.route.toString(), presentationStyle: 'popover'})
  } else {
    await router.push({
      name: props.route
    })
  }

}

</script>