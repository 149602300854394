<template>
  <div class="w-full">
    <div v-if="hasLabel" class="text-white text-base mb-2 ">
      <slot name="label"/>
    </div>
    <slot name="prepend"/>
    <input
        v-model="state"
        class="appearance-none text-white border outline-none border-transparent bg-primary-1 rounded-lg w-full px-4 focus:border-secondary-0 h-[42px]"
    />
  </div>
</template>

<script setup>
import {computed, useSlots} from 'vue'

const state = defineModel()
const slots = useSlots()
// has label - slot is not empty
const hasLabel = computed(() => !!slots.label)

</script>