<template>
  <button @click.stop.prevent="buttonClicked"
          class="bg-secondary-0 px-8 rounded-r-full py-4 text-primary-0 font-bold text-lg relative rounded-bl-full "
          :class="{
            'opacity-50 cursor-not-allowed': props.disabled || props.loading
          }"
  >
    <slot/>
    <div
        class=" w-[54px] h-[54px] z-20 absolute top-[1px] right-[1px] bg-primary-0 border-secondary-0 rounded-full  flex items-center justify-center ">
      <font-awesome-icon v-if="props.loading" :icon="['fad','spinner-third']" class="text-white fa-spin text-2xl"/>
      <font-awesome-icon v-else :icon="props.icon" class="text-white text-lg"/>
    </div>
  </button>
</template>

<script setup>

const props = defineProps({
  icon: {
    type: Array,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click'])

const buttonClicked = () => {
  if (props.disabled || props.loading) return
  emit('click')
}

</script>