<template>
  <div class="w-[24px] h-[24px] rounded  flex items-center justify-center" @click="state= !state"
       :class="[state ? 'bg-secondary-0 border-secondary-0' : 'bg-primary-0 border-primary-0']"
  >
    <font-awesome-icon :icon="['fas', state ? 'check':'x']"
                       class=" text-lg"
                       :class="[!state ? 'text-secondary-0' : 'text-primary-0']"
    />
  </div>
</template>

<script setup>

const state = defineModel()

</script>