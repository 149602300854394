<template>
  <div class="w-screen bg-primary-0 mx-auto max-h-screen h-screen overflow-y-scroll "
       :class="{
    'flex flex-col justify-center':loading,
    'max-w-[620px]':isWeb
  }"
       id="appWrapper"
       :style="{
    'padding-top': topSafeArea + 'px',
    'padding-bottom': bottomSafeArea + 'px'
       }"
  >
    <router-view v-if="!loading" v-slot="{ Component }">
      <transition name="scale" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>

    <template v-else>
      <div class="text-center text-3xl font-bold text-white mt-5 mb-3">
        {{ $t('loading_app') }}
      </div>
      <Loader class="w-[230px] h-auto mx-auto"/>
    </template>
  </div>
</template>

<script setup>
import {computed, watch, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import useContentStore from "@/stores/contentStore";
import useDeliveryStore from "@/stores/deliveryStore";
import usePluStore from "@/stores/pluStore";
import Loader from "@/components/Loader.vue";
import {topSafeArea, bottomSafeArea} from "@/plugins/capacitor/SafeArea";
import {useI18n} from "vue-i18n";
import {platform} from "@/plugins/capacitor/SafeArea";

const route = useRoute()

const deliveryStore = useDeliveryStore()
const contentStore = useContentStore()
const pluStore = usePluStore()

contentStore.loadAppContent()
deliveryStore.loadDeliveryPlaces()
pluStore.loadAppSettings()

const loading = ref(true)
const appIsLoading = computed(() => contentStore.appContentLoading || deliveryStore.loading || pluStore.loading)

watch(() => appIsLoading.value, (value) => {
  if (!value) {
    setTimeout(() => {
      loading.value = false
    }, 1000)
  } else {
    loading.value = true
  }
})

const isWeb = platform === 'web'


</script>
