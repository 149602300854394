import {createRouter, createWebHistory} from 'vue-router'
import TestPage from "@/views/TestPage.vue"
import HomePage from "@/views/HomePage.vue"
import NotFound from "@/views/404.vue"
import PickDelivery from "@/views/PickDelivery.vue"
import SingleMeal from "@/views/SingleMeal.vue"
import MealSideDish from "@/views/MealSideDish.vue"
import Cart from '@/views/Cart.vue'
import Account from "@/views/Account.vue"
import Orders from "@/views/Orders.vue"
import LoginView from '@/views/LogIn.vue'

import beforeEachGuard from "@/router/routerGuard"

export const ROUTE_NAMES = {
    NO_FOUND_VIEW: '404_VIEW',
    TEST_VIEW: 'TEST_VIEW',
    HOME_VIEW: 'HOME_VIEW',
    PICK_DELIVERY_VIEW: 'PICK_DELIVERY_VIEW',
    SINGLE_MEAL_VIEW: 'SINGLE_MEAL_VIEW',
    MEAL_SIDE_DISH: 'MEAL_SIDE_DISH',
    CART_VIEW: 'CART_VIEW',
    LOGIN_VIEW: 'LOGIN_VIEW',
    ACCOUNT_VIEW: 'ACCOUNT_VIEW',
    ORDERS_VIEW: 'ORDERS_VIEW'
}

const routes = [
    {
        path: '/',
        name: ROUTE_NAMES.HOME_VIEW,
        component: HomePage
    },
    {
        path: '/test',
        name: ROUTE_NAMES.TEST_VIEW,
        component: TestPage
    },
    {
        path: '/login',
        name: ROUTE_NAMES.LOGIN_VIEW,
        component: LoginView
    },
    {
        path: '/pick-delivery',
        name: ROUTE_NAMES.PICK_DELIVERY_VIEW,
        component: PickDelivery
    },
    {
        path: '/meal',
        name: ROUTE_NAMES.SINGLE_MEAL_VIEW,
        component: SingleMeal
    },
    {
        path: '/cart',
        name: ROUTE_NAMES.CART_VIEW,
        component: Cart
    },
    {
        path: '/account',
        name: ROUTE_NAMES.ACCOUNT_VIEW,
        component: Account
    },
    {
        path: '/orders',
        name: ROUTE_NAMES.ORDERS_VIEW,
        component: Orders
    },
    {
        path: '/meal-side-dish',
        name: ROUTE_NAMES.MEAL_SIDE_DISH,
        component: MealSideDish
    },
    {
        path: '/:pathMatch(.*)*',
        name: ROUTE_NAMES.NO_FOUND_VIEW,
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

router.beforeEach(beforeEachGuard)

export default router
