<template>
  <MasterLayout>

    <div class="flex flex-col">

      <div class="text-white mt-8 flex items-center font-bold text-4xl">
        <span class="mr-4 italic">{{ $t('account') }}</span>
      </div>

      <hr class="opacity-25 border-white my-4">

      <div class="text-white text-xl font-bold">
        <span class="">{{ $t('acc_phone') }} <i> +{{ user.phone }}</i></span>
      </div>

      <BaseInput v-model="name" class="mt-4">
        <template #label>
          {{ $t('acc_name_username') }}
        </template>
      </BaseInput>

      <div v-if="!nameAndUsernameIsValid" class="bg-danger-0 p-3 rounded-lg mt-1">
        <div class="text-center text-white  ">
          {{ $t('insert_valid_username')}}
        </div>
      </div>

      <div class="flex justify-end mt-4">
        <FloatButton @click="saveUser" :disabled="!nameAndUsernameIsValid" :loading="loading" :icon="['fas','check']">
          <span class="px-6 mr-4">{{ $t('save') }} </span>
        </FloatButton>
      </div>

      <hr class="opacity-25 border-white my-4">

      <div class="flex justify-end mt-4">
        <FloatButton :loading="loadingLogOut" @click="logout" :icon="['fas','door-open']">
          <span class="px-6 mr-4">{{ $t('logout') }} </span>
        </FloatButton>
      </div>

    </div>

  </MasterLayout>
</template>

<script setup>
import {ref, computed} from "vue";
import {useRouter} from "vue-router";
import useSerStore from "@/stores/userStore";
import {ROUTE_NAMES} from "@/router";
import MasterLayout from "@/layouts/MasterLayout.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import FloatButton from "@/components/buttons/FloatButton.vue";
import Supabase from "@/plugins/Supabase";
import {
  checkPermissions,
  removeAllDeliveredNotifications,
  requestPermissions,
  deleteToken
} from "@/plugins/capacitor/Notifications";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const router = useRouter()
const userStore = useSerStore();
const user = userStore.getUser()

const name = ref(user?.user_metadata?.nameAndUsername ?? '')
const loading = ref(false)

const nameAndUsernameIsValid = computed(() => {
  if (name.value.length < 3 || !name.value.includes(' ')) {
    return false
  }

  return true
})

const saveUser = async () => {
  loading.value = true
  const {data: {user}, error} = await Supabase.auth.updateUser({
    data: {
      nameAndUsername: name.value.toString()
    }
  })
  loading.value = false

  console.log('saveUser()', {
    user,
    error
  })

  loading.value = false

  if (error) {
    alert(t('acc_error_save'))
    return
  }

  userStore.setUser(user)

}

const loadingLogOut = ref(false)
const logout = async () => {
  if (!confirm(t('sure_logout'))) return
  loadingLogOut.value = true

  try {
    await removeAllDeliveredNotifications()

    // const perm = checkPermissions()
    // if (perm) {
    //   await requestPermissions()
    //   const userToken = getToken()
    //   if (userToken) {
    //     const {
    //       data,
    //       error
    //     } = await Supabase.from('v2_Hajcer_App_Users_Fcm_Tokens').delete().eq('token', userToken).eq('userId', user.id)
    //     console.log('delete token ', {data, error})
    //   }
    // }
  } catch (e) {
    console.log('logout error token', e)
  }

  try {
    await deleteToken()
  } catch (e) {
    console.log('deleteToken error', e)
  }

  loadingLogOut.value = false
  await userStore.logout();
  await router.push({name: ROUTE_NAMES.HOME_VIEW})
}

</script>