<template>
  <transition name="bounce">
    <div v-if="props.step === props.order" class="mt-5">

      <template v-for="(item,i) in mappedExtraItems" :key="item.id">

        <SingleMealExtraItem v-model="mappedExtraItems[i].quantity"
                             :item="item"
                             :cartItemIndex="props.cartItemIndex"
        />

        <hr v-if=" i < mappedExtraItems.length - 1" class="border-white mb-2 opacity-10">

      </template>
    </div>
  </transition>
</template>

<script setup>
import SingleMealExtraItem from "@/components/meal-side-dish/SingleMealExtraItem.vue";
import {ref, reactive, watch, computed} from 'vue'
import useCartStore from "@/stores/cartStore";

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  step: {
    type: Number,
    required: true
  },
  order: {
    type: Number,
    required: true
  },
  cartItemIndex: {
    type: Number,
    required: true
  }
})

const cartStore = useCartStore()

const mappedExtraItems = ref([])

const {data} = props

data?.products.filter(item => !!item.product).map((item) => {
  let quantity = 0
  const hasInExtra = !!cartStore.cartItems[props.cartItemIndex].extra[item.product.id]
  if (hasInExtra) quantity = cartStore.cartItems[props.cartItemIndex].extra[item.product.id].quantity

  mappedExtraItems.value.push({
    ...item,
    quantity
  })

})

</script>