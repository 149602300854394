<template>

  <MasterLayout :has-navigation-menu="false" :hiddenOverflow="false" sun="lt-to-rb">

    <div class="flex-1 relative" style="">

      <img src="/images/hajcer-logo-big.png" class=" max-h-[120px] mx-auto mt-5"/>

      <template v-if="step === 1">
        <div class="text-center text-3xl font-bold text-white mt-6">
          {{ $t('login') }}
        </div>

        <BaseInput v-model="phone" class="mt-7" :disabled="sending">
          <template #prepend>
            <div class="text-white justify-between text-base mb-2 flex w-full ">
              <span>
                  {{ $t('phone_number') }}
              </span>
              <span
                  :class="phoneIsValid.validate && !phoneIsValid.valid ? 'text-danger-0' : 'text-white'">(+421382992)</span>
            </div>
          </template>
        </BaseInput>

        <div class="flex text-white text-lg mt-5 items-center">
          <BaseChekcbox v-model="terms.terms1"/>
          <span class="ml-3">
            {{ $t('agree_with') }}
            <span @click="terms1" class="text-secondary-0">{{ $t('terms1') }} </span>
          </span>
        </div>

        <div class="flex text-white text-lg mt-3 items-center">
          <BaseChekcbox v-model="terms.terms2"/>
          <span class="ml-3">
            {{ $t('agree_with') }}
            <span @click="terms2" class="text-secondary-0">{{ $t('terms2') }}</span>
          </span>
        </div>

        <div class="flex justify-end mt-5">
          <FloatButton @click="sendOtp"
                       :disabled="!canLogIn"
                       :loading="sending"
                       :icon="['fal','angle-right']"
          >
            <span class="mr-12">{{ $t('login_action') }} </span>
          </FloatButton>
        </div>

      </template>

      <template v-else>

        <div class="text-center text-3xl font-bold text-white mt-6">
          {{ $t('verify_sms_code') }}
        </div>

        <BaseInput v-model="otpCode" class="mt-7" :disabled="veryfing">
          <template #prepend>
            <div class="text-white justify-between text-base mb-2 flex w-full ">
              <span>{{ $t('sms_code') }} </span>
              <span>({{ phone }})</span>
            </div>
          </template>
        </BaseInput>

        <div class="flex justify-between items-center mt-5">
          <div class="text-secondary-0 py-3 px-2"
               :class="{'opacity-50': !canResend}"
               @click="reseedOtp()"
          >
            {{ $t('resend') }}
            <template v-if="resendCounter !== 0">{{ resendCounter }} s</template>
          </div>
          <FloatButton @click="verifyOtp"
                       :disabled="!canVerifyOtpCode"
                       :loading="veryfing"
                       :icon="['fal','angle-right']"
          >
            <span class="mr-12">{{ $t('verify') }} </span>
          </FloatButton>
        </div>

        <div @click="goToStep1" class="flex items-center mt-4 py-3 px-2">
          <font-awesome-icon :icon="['fas', 'arrow-left']"
                             class="text-secondary-0 text-lg"
          />
          <span class="text-white ml-3">{{ $t('use_other_phone_number') }} </span>
        </div>

      </template>

      <div class="w-full flex items-center mt-10">
        <hr class="border-white flex-1 opacity-25">
        <span class="text-white px-5">{{ $t('or') }} </span>
        <hr class="border-white flex-1 opacity-25">
      </div>

      <div class="flex shrink-0 w-full items-center mt-4 justify-center">

        <div @click="gotToHome" class="flex items-center py-3 px-4 justify-center  bg-secondary-0 rounded-2xl">
          <span class="text-primary-0 font-bold text-lg">{{ $t('discover_without_login') }} </span>
          <font-awesome-icon :icon="['fad', 'plate-utensils']"
                             class="text-xl ml-3 font-bold text-primary-0"
          />
        </div>

      </div>

    </div>
  </MasterLayout>

</template>

<script setup>
import {ref, reactive, computed} from "vue";
import {useRouter} from "vue-router";
import BaseInput from "@/components/inputs/BaseInput.vue";
import FloatButton from "@/components/buttons/FloatButton.vue";
import BaseChekcbox from "@/components/inputs/BaseCheckBox.vue";
import Supabase from "@/plugins/Supabase";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {ROUTE_NAMES} from "@/router";
import useSerStore from "@/stores/userStore";
import MasterLayout from "@/layouts/MasterLayout.vue";
import {Browser} from "@capacitor/browser";
import {useI18n} from "vue-i18n";

const router = useRouter()
const userStore = useSerStore()
const {t} = useI18n()

const step = ref(1)

const gotToHome = () => {
  step.value = 1
  router.push({name: ROUTE_NAMES.HOME_VIEW})
}
const resendCounter = ref(60)
const canResend = computed(() => resendCounter.value === 0)
let interval = null
const phone = ref('+421')

const phoneIsValid = computed(() => {
  if (phone.value === '') return {validate: false, valid: false}
  else if (phone.value.toString().startsWith('+') && phone.value.length >= 13) return {validate: true, valid: true}
  else return {validate: true, valid: false}
})

const terms1 = () => Browser.open({
  url: 'https://www.hajcer.sk/main/wp-content/uploads/2024/10/1.Vseobecne_obchodne_podmienky_v2024.pdf',
  presentationStyle: 'popover'
})
const terms2 = () => Browser.open({
  url: 'https://www.hajcer.sk/main/wp-content/uploads/2024/10/3.Zasady_spracuvania_a_ochrany_Osobnych_udajov_a_poucenie_o_cookies_v2024-1.pdf',
  presentationStyle: 'popover'
})

const terms = reactive({
  terms1: false,
  terms2: false
})

const sending = ref(false)
const canLogIn = computed(() => terms.terms1 && terms.terms2 && phoneIsValid.value.validate && phoneIsValid.value.valid)

const goToStep1 = () => {
  phone.value = ''
  step.value = 1
}

const sendOtp = async () => {
  sending.value = true

  let number = phone.value.toString()
  // remove all empty spaces
  number = number.replace(/\s/g, '')

  const {data, error} = await Supabase.auth.signInWithOtp({
    phone: number,
    options: {
      data: {
        nameAndUsername: ''
      }
    }
  })

  console.log('data', {
    data,
    error
  })

  if (error) {
    alert(t('sms_send_error'))
    return
  }

  sending.value = false
  step.value = 2

  resendCounter.value = 60
  interval = setInterval(() => {
    resendCounter.value--
    if (resendCounter.value === 0) clearInterval(interval)
  }, 1000)

}


const reseedOtp = async () => {
  if (!canResend.value) return
  await sendOtp()
  return
  let number = phone.value.toString()
  // remove all empty spaces
  number = number.replace(/\s/g, '')
  const {error} = await Supabase.auth.resend({
    type: 'sms',
    phone: number
  })

  if (error) {
    alert(t('sms_send_error'))
    return
  }

  resendCounter.value = 60
  interval = setInterval(() => {
    resendCounter.value--
    if (resendCounter.value === 0) clearInterval(interval)
  }, 1000)

}

// verify

const otpCode = ref('')
const canVerifyOtpCode = computed(() => otpCode.value.length === 6)
const veryfing = ref(false)

const verifyOtp = async () => {
  veryfing.value = true
  const {data, error} = await Supabase.auth.verifyOtp({
    phone: phone.value,
    token: otpCode.value,
    type: 'sms'
  })
  console.log('data', {
    data,
    error
  })

  const {data: dataSession, error: errorSession} = await Supabase.auth.getSession()
  console.log('dataSession', dataSession)

  const {data: {user}} = await Supabase.auth.getUser()
  console.log('user', user)
  veryfing.value = false
  if (errorSession || !user) {
    alert(t('sms_verify_error'))
    return
  }

  userStore.setUser(user)
  await router.push({name: ROUTE_NAMES.HOME_VIEW})
  // const { data, error } = await supabase.auth.setSession({
  //   access_token,
  //   refresh_token
  // })


}

</script>