<template>

  <MasterLayout :has-navigation-menu="false" sun="lt-to-rb" :hidden-overflow="true">

    <div class="overflow-y-auto">

      <div class="flex-1">

        <template v-if="overFlow">
          <img src="/images/hajcer-logo-big.png" class=" max-h-[120px] mx-auto mt-5"/>

          <div class="text-white font-medium mt-8 opacity-90 text-2xl font-roboto">
            {{ $t('delivery_type') }}
          </div>

          <PickDeliveryOptionButton :icon="['fas','store']" :type="DELIVERY_TYPES.PERSONAL_PICK_UP">
            {{ $t('personal_pickup') }}
          </PickDeliveryOptionButton>

          <PickDeliveryOptionButton :icon="['fas','truck-utensils']" :type="DELIVERY_TYPES.ADDRESS">
            {{ $t('to_address') }}
          </PickDeliveryOptionButton>

        </template>

        <template v-if="deliveryStore.isPersonalPickUp">

          <div class="text-white opacity-75 font-medium mt-8  text-xl font-roboto">
            {{ $t('select_pic_up_place') }}
          </div>

          <RestaurantButton v-for="place in PICK_UP_PLACES"
                            :icon="['fas','truck-utensils']"
                            :key="place.id"
                            :type="place.id"
          >
            {{ place.title }}
          </RestaurantButton>

        </template>

        <template v-else>

          <div class="text-white opacity-75 font-medium mt-8  text-xl font-roboto">
            {{ $t('address_delivery') }}
          </div>

          <!--          <FloatRoundButton @click="useGps" :icon="['fas','location-dot']">-->
          <!--            Použiť GPS-->
          <!--          </FloatRoundButton>-->

          <BaseInput v-model="deliveryCity" class="mt-4">
            <template #label>
              {{ $t('city') }}
            </template>
          </BaseInput>

          <BaseInput v-model="deliveryStore.addressDelivery.street" class="mt-4">
            <template #label>
              {{ $t('street') }}
            </template>
          </BaseInput>

          <div v-if="deliveryStore.invalidDeliveryCity" class="bg-danger-0 p-3 text-center text-white rounded-lg mt-3">
            {{ $t('no_delivery_option') }}
          </div>

          <div v-if="deliveryStore.addressDeliveryCityIsSet"
               class="border mt-4 flex items-center justify-between border-secondary-0 rounded-lg p-4 ">

            <div class="text-white">
              {{ $t('delivery_price') }}
            </div>

            <div class="font-bold text-lg text-secondary-0">
              <template v-if="!deliveryStore.isDeliveryFree">
                {{ deliveryStore.deliveryPrice }} €
              </template>
              <template v-else>
                {{ $t('free') }}
              </template>
            </div>
          </div>

        </template>

      </div>

      <div class="flex pb-4 mt-5 justify-end">

        <FloatButton v-if="deliveryStore.deliveryIsSet" @click="gotToHome" :icon="['fal','angle-right']">
          <span class="mr-12">
              {{ $t('go_to_pick_meal') }}
          </span>
        </FloatButton>

      </div>
    </div>

  </MasterLayout>
</template>

<script setup>
import {ref, watch} from "vue"
import BaseInput from "@/components/inputs/BaseInput.vue"
import PickDeliveryOptionButton from '@/components/pick-delivery/OptionButton.vue'
import RestaurantButton from '@/components/pick-delivery/RestaurantButton.vue'
import useDeliveryStore, {DELIVERY_TYPES, PICK_UP_PLACES} from "@/stores/deliveryStore"
import {useRouter} from "vue-router";
import FloatButton from "@/components/buttons/FloatButton.vue";
// import {getLocation} from "@/services/Geolocation";
import useContentStore from "@/stores/contentStore";
import useCartStore from "@/stores/cartStore";
import MasterLayout from "@/layouts/MasterLayout.vue";
import {useI18n} from "vue-i18n"

const deliveryStore = useDeliveryStore()
const contentStore = useContentStore()
const cartStore = useCartStore()

const {t} = useI18n()

const router = useRouter()

const gotToHome = () => {
  cartStore.itemsInCart = []
  contentStore.mapAppContent()
}

const deliveryCity = ref(deliveryStore.addressDelivery.city ? deliveryStore.addressDelivery.city.name : '')

watch(() => deliveryCity.value, (val) => {
  deliveryStore.addressDelivery.city = null
  const upperCaseNoSpaces = str => (str ?? '').toUpperCase().replace(/[ ]+/g, "");
  const foundCity = deliveryStore.deliveryPlaces.find((place) => upperCaseNoSpaces(place.name) === upperCaseNoSpaces(val) || (place?.variations ?? [])?.find(alternative => upperCaseNoSpaces(val) === upperCaseNoSpaces(alternative))) ?? false;
  if (foundCity) deliveryStore.addressDelivery.city = foundCity
})

// const useGps = () => {
//
//   getLocation().then((location) => {
//     console.log('location', location)
//     if (location.error) {
//       return alert('Nepodarilo sa získať polohu')
//     }
//     deliveryCity.value = location.city
//     deliveryStore.addressDelivery.street = location.street
//   }).catch((e) => {
//     console.log('location error', e)
//   })
//
// }

import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {platform} from "@/plugins/capacitor/SafeArea";

const overFlow = ref(true)
if (platform === 'ios') {

  Keyboard.addListener('keyboardWillShow', (info) => {
    overFlow.value = false
  });

  Keyboard.addListener('keyboardWillHide', (info) => {
    console.log('keyboardWillHide', info)
    overFlow.value = true
  });

  Keyboard.setResizeMode({mode: KeyboardResize.Native})
  Keyboard.setScroll({isDisabled: true})
  Keyboard.setAccessoryBarVisible({isVisible: true})
}

</script>