<template>
  <div class="w-[24px] h-[24px] rounded  flex items-center border border-secondary-0 justify-center" @click="state= !state"
       :class="[state ? 'bg-secondary-0 ' : 'bg-primary-0 ']"
  >
    <font-awesome-icon v-if="state"
                       :icon="['fas', 'check']"
                       class="text-primary-0 text-lg"
    />
  </div>
</template>

<script setup>

const state = defineModel()

</script>