import {createI18n, useI18n} from 'vue-i18n'
import {appTexts} from "@/content/lists"
import storage from "@/services/Storage.service"
import {isRef, unref} from 'vue'

export const LANG_KEY = 'app_lang'
storage.hasItem(LANG_KEY) || storage.setItem(LANG_KEY, 'sk')

export const i18n = createI18n({
    locale: storage.getItem(LANG_KEY),
    fallbackLocale: 'sk',
    legacy: false,
    messages: {
        ...appTexts
    }
})

export const translateVal = (key, obj) => {

    if (isRef(obj)) obj = unref(obj.value)

    // obj = JSON.parse(JSON.stringify(obj))

    const returnDefault = () => obj[key]
    if (i18n.global.locale.value === 'sk') return returnDefault()
    else {
        let searchKey = key + '_' + i18n.global.locale.value
        console.log('searchKey', searchKey)
        const tVal = obj[searchKey]
        if (!tVal || tVal === '') return returnDefault()
        else return tVal
    }
}

export default app => {

    app.use(i18n)

}