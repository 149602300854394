<template>

  <div class="flex mb-2 justify-between py-1 w-full">

    <div class="flex  items-center">
      <div class="w-[121px] shrink-0">
        <img :src="`${IMG_URL}/${item.product?.images[0]}`"
             class="shrink-0 max-w-[90px] max-h-[90px] w-auto h-auto mx-auto"
        />
      </div>
      <div class="">
        <div class="text-lg text-white font-bold text-secondary-0">
          {{ translateVal('title', item.product) }}
          <!--          {{ item.product.title }}-->
        </div>
        <div class="text-white text-base">
          <span class="opacity-75">Cena:</span>
          <span v-if="item.product?.pluItem"
                class="text-secondary-0 font-bold ml-2">{{ item.product?.pluItem.pluPrice }} €</span>
        </div>
        <div class="text-white text-base">
          <span class="opacity-75">Hmotnosť:</span> <span class="opacity-90">
                {{ item.product.weight }}
              </span>

        </div>
        <div class="text-white text-base">
          <span class="opacity-75">Alergeny:</span>
          <span class="opacity-90">{{ item.product.allergens }}</span>
        </div>
      </div>
    </div>

    <QuantityCounter v-model="state" :is-vertical="true"/>

  </div>

</template>

<script setup>
import QuantityCounter from "@/components/inputs/QuantityCounter.vue"
import {watch} from "vue";
import useCartStore from "@/stores/cartStore";
import {IMG_URL} from "@/content/lists";
import {translateVal} from "@/plugins/l18m";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  cartItemIndex: {
    type: Number,
    required: true
  }
})

const state = defineModel()
const cartStore = useCartStore()

watch(() => props.item.quantity, (newValue) => {

  const hasInExtra = !!cartStore.cartItems[props.cartItemIndex].extra[props.item.product.id]

  if (hasInExtra && newValue === 0) {
    delete cartStore.cartItems[props.cartItemIndex].extra[props.item.product.id]
    return
  }

  cartStore.cartItems[props.cartItemIndex].extra[props.item.product.id] = JSON.parse(JSON.stringify(props.item))

})

</script>