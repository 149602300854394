<!-- SlidingMenu.vue -->
<template>
  <div>
    <!-- Overlay -->
    <div v-if="layoutStore.sideMenuOpened"
         class="fixed inset-0 z-100 bg-primary-0 bg-opacity-50 backdrop-blur-sm transition-opacity duration-300"
         @click="layoutStore.closeSideMenu()"
         :style="{
    'padding-top': topSafeArea + 'px',
    'padding-bottom': bottomSafeArea + 'px'
       }"
    >
      <div @click="layoutStore.closeSideMenu()"
           class=" w-[52px] h-[52px] z-20 mt-1  flex items-center justify-center ">
        <font-awesome-icon :icon="['fas','times']" class="text-white text-4xl"/>
      </div>

    </div>

    <!-- Menu -->
    <div :style="{
    'padding-top': topSafeArea + 'px',
    'padding-bottom': bottomSafeArea + 'px'
       }"
         :class="[
        'fixed top-0 flex flex-col justify-between px-5 z-120 right-0 h-full w-[70vw] rounded-l-2xl  bg-primary-0 transform transition-transform duration-300 ease-in-out ',
        layoutStore.sideMenuOpened ? 'translate-x-0' : 'translate-x-full'
      ]"
         style="background-image: radial-gradient(
            circle at 105% 105%,
            rgba(251, 182, 101, 0.5) 0%,
            rgba(251, 182, 101, 0.4) 3%,
            rgba(251, 182, 101, 0) 25%
        );">

      <div class="">

        <img src="/images/hajcer-logo-big.png" class=" max-h-[80px] mt-2 mx-auto "/>

        <ul class="space-y-4 mt-5">
          <li v-for="category in contentStore.categories"
              :key="category.id"
              class="text-white justify-between flex items-center  font-roboto font-medium text-lg hover:text-gray-300 cursor-pointer"
              @click="contentStore.setSelectedCategoryId(category.id)"
          >
            <div class="flex space-x-4 items-center">
              <div class="w-[42px]">
                <span v-html="category.icon" class=" text-4xl fill-secondary-0"/>
                <!--                <font-awesome-icon :icon="category.icon" class=" text-4xl text-secondary-0"/>-->
              </div>
              <span class="font-normal">{{ translateVal('name', category) }}</span>
            </div>
            <div class="w-[8px] h-[8px] rounded-full"
                 :class="contentStore.selectedCategoryId === category.id ? 'bg-secondary-0' : 'bg-primary-0'">
            </div>
          </li>
        </ul>

        <hr class="text-white my-4 opacity-10">

        <template v-if="userStore.isLogged">

          <MainMenuLink :icon="['fal','user']" :route="ROUTE_NAMES.ACCOUNT_VIEW">
            {{ $t('link_acc') }}
          </MainMenuLink>

          <MainMenuLink :icon="['fat','list-dropdown']" :route="ROUTE_NAMES.ORDERS_VIEW" class="mt-3">
            {{ $t('link_orders') }}
          </MainMenuLink>

        </template>

        <MainMenuLink v-else :icon="['fal','user']" :route="ROUTE_NAMES.LOGIN_VIEW">
          {{ $t('link_login') }}
        </MainMenuLink>

        <hr class="text-white my-4 opacity-10">

        <MainMenuLink :icon="['fal','blog']" :is-external="true" route="https://www.hajcer.sk/main/blog/">
          {{ $t('link_blog') }}
        </MainMenuLink>

        <MainMenuLink :icon="['fal','turkey']" :is-external="true"
                      route="https://www.hajcer.sk/main/denne-menu-hajcer-bowling-partizanske/" class="mt-3">
          {{ $t('dayli_menu_pe') }}
        </MainMenuLink>

        <MainMenuLink :icon="['fal','phone']" :is-external="true" route="https://www.hajcer.sk/main/kontakt/"
                      class="mt-3">
          {{ $t('link_contact') }}
        </MainMenuLink>


      </div>

      <div class="flex justify-between">
        <img v-for="lang in languages"
             :src="`/images/lang/${lang}.svg`"
             class="w-[32px] h-auto"
             :class="{'opacity-50':lang !== locale}"
             @click="changeLanguage(lang)"
        />

      </div>

    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router"
import useLayoutStore from "@/stores/layoutStoreStore"
import useContentStore from "@/stores/contentStore"
import useSerStore from "@/stores/userStore"
import MainMenuLink from "@/components/layout/MainMenuLink.vue"
import {ROUTE_NAMES} from "@/router"
import {bottomSafeArea, topSafeArea} from "@/plugins/capacitor/SafeArea"
import {useI18n} from "vue-i18n"
import storage from "@/services/Storage.service"
import {LANG_KEY, translateVal} from "@/plugins/l18m"

const router = useRouter()
const layoutStore = useLayoutStore()
const contentStore = useContentStore()
const userStore = useSerStore();

const languages = ['sk', 'cz', 'en', 'de']
const {t, locale} = useI18n()

const changeLanguage = (lang) => {
  storage.setItem(LANG_KEY, lang)
  locale.value = lang
}

</script>