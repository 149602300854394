<template>
  <div @click="clickHandler"
       class="border border-grey-0  rounded-br-2xl rounded-tl-2xl px-4 py-3 flex items-center space-x-4 "
       :class="[
           {'opacity-50':props.disabled},
           props.selected ? 'bg-secondary-0 flex justify-between border-secondary-0' : 'bg-primary-0'
           ]"

  >
    <div class="font-roboto text-lg " :class="[props.selected ? 'text-primary-0 font-bold':'text-white']">
      <font-awesome-icon v-if="props.icon.length > 0"
                         :icon="props.icon"
                         class="mr-2 text-lg text-primary-0"
                         :class="[props.selected ? 'text-primary-0':'text-white']"
      />
      <slot/>
    </div>
    <font-awesome-icon :icon="['fal','check']" class=" text-lg text-primary-0"/>
  </div>
</template>

<script setup>

const props = defineProps({
  icon: {
    type: Array,
    default: () => []
  },
  selected: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click'])

const clickHandler = () => {
  if (props.disabled) return
  emit('click')
}

</script>