<template>
  <div class="border border-grey-0 mt-4 rounded-br-2xl rounded-tl-2xl px-4 py-3 flex items-center space-x-4 "
       @click="deliveryStore.setPickUpPlace(props.type)"
       :class="[isSelected ? 'bg-secondary-0 flex justify-between border-secondary-0' : 'bg-primary-0']"
  >
    <div class="font-roboto text-lg " :class="[isSelected ? 'text-primary-0':'text-white']">
      <slot/>
    </div>
    <font-awesome-icon :icon="['fal','check']" class=" text-lg text-primary-0"/>
  </div>
</template>

<script setup>
import {computed} from "vue"
import useDeliveryStore from "@/stores/deliveryStore"

const props = defineProps({
  type: {}
})

const deliveryStore = useDeliveryStore()
const isSelected = computed(() => deliveryStore.pickUpPlace === props.type)

</script>