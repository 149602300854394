<template>
  <div class="w-full">
    <div class="text-white text-base mb-2">
      <slot name="label"/>
    </div>
    <textarea
        v-model="state"
        class="appearance-none text-white border outline-none border-transparent bg-primary-1 rounded-lg w-full px-4 py-2 focus:border-secondary-0 h-[100px] resize-none"
    ></textarea>
  </div>
</template>

<script setup>
const state = defineModel()
</script>