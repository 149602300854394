console.log('Hajcer app 20 main.js')
import './assets/style/main.scss'

console.log(window.location)
console.log(window.location.href)

import {createApp} from 'vue'
import {createPinia} from 'pinia'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)

import InstallPlugin from "@/plugins/Install"

const startApp = async () => {
    await InstallPlugin(app)
    app.mount('#app')
}

startApp().finally(() => console.log('App started'))